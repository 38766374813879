<template>
	<div class="mt-5 budget-breakdown-tab financial-overview">
		<div class="scroll-tabs-financial">
			<ul class="nav nav-tabs" id="myTab" role="tablist">
				<li class="nav-item">
					<a
						class="nav-link active"
						id="cost-tab"
						data-toggle="tab"
						href="#cost"
						role="tab"
						aria-controls="cost"
						aria-selected="true"
						v-on:click="myFilter"
					>
						<div>Current
							<a class="tooltipss">
								<img src="@/assets/images/info-circle.png"/>
								<span class="tooltiptext tt">The project profits and budgets based on the most current budgets (including all revisions and changes) and to-date costs of the project.</span>
							</a>
						</div>
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						id="finance-tab"
						data-toggle="tab"
						href="#finance"
						role="tab"
						aria-controls="finance"
						aria-selected="false"
					>Baseline
						<a class="tooltipss">
							<img src="@/assets/images/info-circle.png"/>
							<span class="tooltiptext">The project profits and budgets based on the original baseline budget (based on actual quotes) of the project.</span>
						</a>
					</a>
				</li>
				<li class="nav-item">
					<a
						class="nav-link"
						id="proforma-tab"
						data-toggle="tab"
						href="#proforma"
						role="tab"
						aria-controls="proforma"
						aria-selected="false"
					>Proforma
						<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
							<span class="tooltiptext proforma-tt">The project profits and budgets based on the proforma budget (feasibility based on market data and estimates) of the project.</span>
						</a></a
					>
				</li>
			</ul>
		</div>
		<div class="tab-content" id="myTabContent">
			<div
				class="tab-pane fade show active"
				id="cost"
				role="tabpanel"
				aria-labelledby="cost-tab"
			>
				<div class="row align-items-start">
					<div class="col-lg-3 mt-4 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-border section3-color bg-color-purpal">
								Sale Profits
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-7 pl-2 pr-0">
									<p class="financial-overview-heading">Net Profit
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Net profit refers to the amount of money the seller receives after all costs and expenses are paid.</span>
										</a>
									</p>
								</div>
								<div class="col-5 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[8].cells[3].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-7 pl-2 pr-0">
									<p class="financial-overview-heading">Return on Cost
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The rate of return on the project based on the cost. It is the net profit divided by the total costs.</span>
										</a>
									</p>
								</div>
								<div class="col-5 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[10].cells[3].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-7 pl-2 pr-0">
									<p class="financial-overview-heading">Return on Sales
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The rate of return on the project based on the sales. It is the net profit divided by the total sales.</span>
										</a>
									</p>
								</div>
								<div class="col-5 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[11].cells[3].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-border section3-color bg-color-gray">
								Leasing Income
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">NOI
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Net operating income refers to the annual income received from the project after all operation costs and expenses are paid</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[14].cells[3].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">Additional cash flow
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The cash flow received as result of the new development addition.</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[20].cells[3].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">Total cash flow
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The total cash flow received from both the new development addition and existing real estate on the property.</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[23].cells[3].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-borderr section3-color bg-color-blue">
								Sales/Leasing Rates
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Sale Price
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Total amount received as a result of what the buyer pays.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[26].cells[3].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Net Rent
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Amount received annually after subtraction off all expenses. These expenses include taxes, insurance, utilities and maintenance.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[27].cells[3].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-borderrr section3-color bg-color-light-gray">
								Unit Rate Costs
								<a class="tooltipss">
									<img src="@/assets/images/info-circle.png"/>
									<span class="tooltiptext unit-rate">Unit rate costs refer to cost per square foot and equal the total costs divided by the total development square footage.</span>
								</a>
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Construction
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Construction Cost refer to the total of the soft and hard cost.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[33].cells[3].displayValue}} /sq.ft.</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Development
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext develop-tt">Development cost refer to the grand total that the development will cost a and include soft, hard, finance and sales/leasing costs.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[38].cells[3].displayValue}} /sq.ft.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-start">
					<div class="col-12 col-lg-5 mb-4">
						<div class="dashboard-content__section-border mb-md-0 pb-2">
							<p class="dashboard-content__sub-section">Budget</p>
							<div class="tab-content" id="pills-tabContent">
								<div
									class="tab-pane fade show active"
									id="pills-current"
									role="tabpanel"
									aria-labelledby="pills-current-tab"
								>
									<div class="row ml-2 mr-2 mt-4" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading">Soft Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are NOT considered directly related to the construction activities.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value">{{chart.rows[2].cells[5].displayValue}}</p>
										</div>
									</div>
									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading">Hard Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are directly related to the construction activities.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value">{{chart.rows[37].cells[5].displayValue}}</p>
										</div>
									</div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading">Finance Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are related to obtaining and utilizing financing and loans for the project.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value">{{chart.rows[57].cells[5].displayValue}}</p>
										</div>
									</div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading mb-0">
												Sales/Leasing Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are related to the selling and/or leasing of the project.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value mb-0">{{chart.rows[61].cells[5].displayValue}}</p>
										</div>
									</div>
									<div class="dashed-border"></div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading">Total costs</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-overview-value">{{chart.rows[1].cells[5].displayValue}}</p>
										</div>
									</div>
								</div>								
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-4 mb-4">
						<div class="dashboard-content__section-border mb-md-0 chart pb-2">
							<p class="dashboard-content__sub-section">Budget Progress</p>
							<div class="ml-5 mr-5 break-budget">
								<p><span class="mb-2 d-block">Current budget</span>
									<span v-if="!isLoadingChart" class="cost-css">{{chart.rows[1].cells[5].displayValue}}</span></p>
							</div>
							<div class="ml-5 mr-5 wrap-finace-view" style="margin-top: -20px;">
								<canvas id="doughnutChart"></canvas>
								<!-- <div class="actual-cost-percentage">14%</div> -->
							</div>
							<div class="ml-3 mr-3 mt-4 d-flex justify-content-between align-items-center finacal-bud">
								<div>
									<p class="actual-cost color-grey mb-1">
										<span></span>To-date Costs
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext finan">All up-to date and current expense and costs that have been incurred on your project.</span>
										</a>
									</p>
									<p v-if="!isLoadingChart" class="cost-value cost-css ml-3">{{chart.rows[1].cells[7].displayValue}}</p>
								</div>
								<div>
									<p class="total-cost color-grey mb-1"><span></span>Total Costs Balance
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"
																		   class=""/>
											<span class="tooltiptext finan">Any remaining amount that is left in your project budget</span>
										</a>
									</p>
									<p v-if="!isLoadingChart" class="cost-value cost-css ml-3">{{chart.rows[1].cells[8].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-12 col-lg-3 mb-4 document-section">
						<div class="dashboard-content__section-border mb-md-0 pb-2">
							<p class="dashboard-content__sub-section bg-color-golden">Documents</p>
							<div class="documentation-list no-border">
								<button class="bg-transparent"
								@click="redirectToLink(dashboard.widgets[88].contents.shortcutData[0].hyperlink.url)" v-if="!isLoadingDoc">
									<div class="d-flex justify-content-start align-items-center">
										<div class="document-list-icon mr-2"></div>
											<p class="mb-0 documentation-list__doc-list fina">Financial
												<a class="tooltipss"><img
													src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext finan">All documents related to the financial status and loans & mortgages on the project.</span>
												</a>
											</p>
									</div>
								</button>								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="tab-pane fade"
				id="finance"
				role="tabpanel"
				aria-labelledby="finance-tab"
			>
				<div class="row align-items-start">
					<div class="col-lg-3 col-12 mt-4 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-border section3-color bg-color-purpal">
								Sale Profits
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Net Profit
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Net profit refers to the amount of money the seller receives after all costs and expenses are paid.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[8].cells[2].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Return on Cost
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The rate of return on the project based on the cost. It is the net profit divided by the total costs.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[10].cells[2].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Return on Sales
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The rate of return on the project based on the sales. It is the net profit divided by the total sales.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[11].cells[2].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-border section3-color bg-color-gray">
								Leasing Income
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">NOI
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Net operating income refers to the annual income received from the project after all operation costs and expenses are paid</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[14].cells[2].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">Additional cash flow
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The cash flow received as result of the new development addition.</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[20].cells[2].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">Total cash flow
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The total cash flow received from both the new development addition and existing real estate on the property.</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[23].cells[2].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-borderr section3-color bg-color-blue">
								Sales/Leasing Rates
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Sale Price
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Total amount received as a result of what the buyer pays.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[26].cells[2].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Net Rent
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Amount received annually after subtraction off all expenses. These expenses include taxes, insurance, utilities and maintenance.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[27].cells[2].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-borderrr section3-color bg-color-light-gray">
								Unit Rate Costs
								<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
									<span class="tooltiptext unit-rate">Unit rate costs refer to cost per square foot and equal the total costs divided by the total development square footage.</span>
								</a>
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Construction
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Construction Cost refer to the total of the soft and hard cost.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[33].cells[2].displayValue}} /sq.ft.</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Development
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext develop-tt">Development cost refer to the grand total that the development will cost a and include soft, hard, finance and sales/leasing costs.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[38].cells[2].displayValue}}
										/sq.ft.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-4 align-items-start">
					<div class="col-12 col-lg-5 mb-4">
						<div class="dashboard-content__section-border mb-md-0 pb-2">
							<p class="dashboard-content__sub-section">Budget</p>
							<div class="tab-content" id="pills-tabContent">
								<div
									class="tab-pane fade show active"
									id="pills-current"
									role="tabpanel"
									aria-labelledby="pills-current-tab"
								>
									<div class="row ml-2 mr-2 mt-4" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading tooltipss">Soft Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are NOT considered directly related to the construction activities.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value color-black">{{chart.rows[2].cells[3].displayValue}}</p>
										</div>
									</div>
									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading tooltipss">Hard Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are directly related to the construction activities.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value color-black">{{chart.rows[37].cells[3].displayValue}}</p>
										</div>
									</div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading tooltipss">Finance Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are related to obtaining and utilizing financing and loans for the project.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value color-black">{{chart.rows[57].cells[3].displayValue}}</p>
										</div>
									</div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading mb-0 tooltipss">
												Sales/Leasing Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are related to the selling and/or leasing of the project.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value mb-0">{{chart.rows[61].cells[3].displayValue}}</p>
										</div>
									</div>
									<div class="dashed-border"></div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading">Total costs</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-overview-value">{{chart.rows[1].cells[3].displayValue}}</p>
										</div>
									</div>
								</div>					
							</div>
						</div>
					</div>
					<div class=" col-12 col-lg-3 mb-4 document-section">
						<div class="dashboard-content__section-border mb-md-0 pb-2">
							<p class="dashboard-content__sub-section bg-color-golden">Documents</p>
							<div class="documentation-list no-border">
								<button class="bg-transparent"
									@click="redirectToLink(dashboard.widgets[88].contents.shortcutData[0].hyperlink.url)" v-if="!isLoadingDoc">									
									<div class="d-flex justify-content-start align-items-center">
										<div class="document-list-icon mr-2"></div>

										<p class="mb-0 documentation-list__doc-list tooltipss fina">Financial
											<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
												<span class="tooltiptext">All documents related to the financial status and loans & mortgages on the project.</span>
											</a>
										</p>										
									</div>
								</button>								
							</div>
						</div>
					</div>
				</div>
			</div>

			<div
				class="tab-pane fade"
				id="proforma"
				role="tabpanel"
				aria-labelledby="proforma-tab"
			>
				<div class="row align-items-start">
					<div class="col-lg-3 col-12 mt-4 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-border section3-color bg-color-purpal">
								Sale Profits
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Net Profit
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Net profit refers to the amount of money the seller receives after all costs and expenses are paid.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[8].cells[1].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Return on Cost
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The rate of return on the project based on the cost. It is the net profit divided by the total costs.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[10].cells[1].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Return on Sales
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The rate of return on the project based on the sales. It is the net profit divided by the total sales.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[11].cells[1].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-border section3-color bg-color-gray">
								Leasing Income
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">NOI
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Net operating income refers to the annual income received from the project after all operation costs and expenses are paid</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[14].cells[1].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">Additional cash flow
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The cash flow received as result of the new development addition.</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[20].cells[1].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-8 pl-2 pr-0">
									<p class="financial-overview-heading">Total cash flow
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">The total cash flow received from both the new development addition and existing real estate on the property.</span>
										</a>
									</p>
								</div>
								<div class="col-4 pl-0">
									<p class="financial-overview-value percentage">{{financialOverview.rows[23].cells[1].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-borderr section3-color bg-color-blue">
								Sales/Leasing Rates
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Sale Price
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Total amount received as a result of what the buyer pays.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[26].cells[1].displayValue}}</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Net Rent
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Amount received annually after subtraction off all expenses. These expenses include taxes, insurance, utilities and maintenance.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[27].cells[1].displayValue}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-3 col-12 mt-md-0 pl-2 pr-2 backup1 mb-4">
						<div class="dashboard-content__section-border pb-2">
							<p class="dashboard-content__sub-section new-borderrr section3-color bg-color-light-gray">
								Unit Rate Costs
								<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
									<span class="tooltiptext unit-rate">Unit rate costs refer to cost per square foot and equal the total costs divided by the total development square footage.</span>
								</a>
							</p>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Construction
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext">Construction Cost refer to the total of the soft and hard cost.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[33].cells[1].displayValue}}
										/sq.ft.</p>
								</div>
							</div>
							<div class="row ml-2 mr-2" v-if="!isLoading">
								<div class="col-6 pl-2 pr-0">
									<p class="financial-overview-heading">Development
										<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
											<span class="tooltiptext develop-tt">Development cost refer to the grand total that the development will cost a and include soft, hard, finance and sales/leasing costs.</span>
										</a>
									</p>
								</div>
								<div class="col-6 pl-0">
									<p class="financial-overview-value">{{financialOverview.rows[38].cells[1].displayValue}}
										/sq.ft.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-start">
					<div class="col-12 col-lg-5 mb-4">
						<div class="dashboard-content__section-border mb-md-0 pb-2">
							<p class="dashboard-content__sub-section">Budget</p>
							<div class="tab-content" id="pills-tabContent">
								<div
									class="tab-pane fade show active"
									id="pills-current"
									role="tabpanel"
									aria-labelledby="pills-current-tab"
								>
									<div class="row ml-2 mr-2 mt-4" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading tooltipss">Soft Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are NOT considered directly related to the construction activities.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value">{{chart.rows[2].cells[2].displayValue}}</p>
										</div>
									</div>
									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading tooltipss">Hard Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are directly related to the construction activities.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value">{{chart.rows[37].cells[2].displayValue}}</p>
										</div>
									</div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading tooltipss">Finance Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are related to obtaining and utilizing financing and loans for the project.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value">{{chart.rows[57].cells[2].displayValue}}</p>
										</div>
									</div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading mb-0 tooltipss">
												Sales/Leasing Costs
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All costs that are related to the selling and/or leasing of the project.</span>
												</a>
											</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-cost-value mb-0">{{chart.rows[61].cells[2].displayValue}}</p>
										</div>
									</div>
									<div class="dashed-border"></div>

									<div class="row ml-2 mr-2" v-if="!isLoadingChart">
										<div class="col-6 pl-2 pr-0">
											<p class="financial-cost-heading">Total costs</p>
										</div>
										<div class="col-6 pl-0">
											<p class="financial-overview-value">{{chart.rows[1].cells[2].displayValue}}</p>
										</div>
									</div>
								</div>					
							</div>
						</div>
					</div>
					<div class=" col-12 col-lg-3 mb-4 document-section">
						<div class="dashboard-content__section-border mb-md-0 pb-2">
							<p class="dashboard-content__sub-section bg-color-golden">Documents</p>
							<div class="documentation-list no-border">
								<button class="bg-transparent"
								@click="redirectToLink(dashboard.widgets[88].contents.shortcutData[0].hyperlink.url)" v-if="!isLoadingDoc">
									<div class="d-flex justify-content-start align-items-center">
										<div class="document-list-icon mr-2"></div>
										<div class="">
											<p class="mb-0 documentation-list__doc-list tooltipss fina">Financial
												<a class="tooltipss"><img src="@/assets/images/info-circle.png"/>
													<span class="tooltiptext">All documents related to the financial status and loans & mortgages on the project.</span>
												</a>
											</p>
										</div>
									</div>
								</button>								
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>	
</template>
<script lang="js">
import { connectUrl } from '@/helpers/constants'
import { mapState } from 'vuex'
import Chart from 'chart.js/auto';
export default {
	name: 'FinancialOverview',
	el: '#myTab',
	data() {
      return { 
        financialOverview:[], 
        isLoading:true,  
		dashboard:[],
		chart:[],
		isLoadingDoc:true, 
		isLoadingChart:true,     
        smartsheetUrl:connectUrl          
      }	  	
    },
	created() {
		this.getSmartsheet();
	},	
	computed: {		
		...mapState(['workspace', 'projectId'])
	},
	methods: {
		getSmartsheet() {  
			fetch(`${this.smartsheetUrl}get-sheet?id=${this.workspace.folders[this.projectId].folders[1].folders[2].folders[0].sheets[1].id}`) 
			.then((res) => res.json())
			.then((result) => {
				if(result.status == 'success'){    
					this.financialOverview = result.data					
					this.isLoading = false            
				}
			}); 			

			fetch(`${this.smartsheetUrl}get-sheet?id=${this.workspace.folders[this.projectId].folders[1].folders[2].folders[0].sheets[0].id}`) 
			.then((res) => res.json())
			.then((result) => {
				if(result.status == 'success'){      
					this.chart = result.data					
					this.isLoadingChart = false
					this.doughnutChart(result.data)           
				}
			}); 			
			if(this.workspace.folders[this.projectId].folders[0].folders[0].sights && this.workspace.folders[this.projectId].folders[0].folders[0].sights[0]){
				fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[0].folders[0].sights[0].id}`)
				.then((res) => res.json())
				.then((result) => {
					if(result.status == 'success'){
						this.isLoadingDoc = false
						this.dashboard = result.data					
					}
				});
			}
		},
		redirectToLink(link){
			window.open(link, '_blank')		
		},
		doughnutChart(data){
			const ctx = document.getElementById('doughnutChart');
			const doughnutChart = new Chart(ctx, {
				type: 'doughnut',
				data: {
					labels: ["To-date Costs", "Costs Balance"],
					datasets: [{
						label: '# of Votes',
						data: [
							data.rows[1].cells[7].value,
                    		data.rows[1].cells[8].value
						],
						backgroundColor: [
							'rgb(23,68,134)',
							'rgb(233,232,237)',

						],
						borderColor: [
							'rgb(23,68,134)',
							'rgb(233,232,237)'
						],
						borderWidth: 1,
						cutout: '70%',
						circumference: 180,
						rotation: 270
					}]
				},
				options: {
					aspectRatio: 1.5,
					plugins: {
					legend: {
						display: false
					}
					},
				}
			});
		}
	}	
}

</script>

<style lang="scss">
@import "@/assets/styles/_mixin.scss";

.section1-color {
	background: #ced1f4;
}

.section2-color {
	background: #e1e7eb;
	margin-bottom: 6px;
}

.section3-color {
	background: #e6c0cd;
}

.profits-content {
	background: #f9f9fb;
	//background: #e6c0cd;
	border-radius: 8px 8px 0px 0px;
	padding: 12px;
	margin: 10px;

	> p {
		text-align: left;
		margin-bottom: 0;
		font-family: "DM Sans";
		font-weight: 500;
		font-size: 16px;
		color: #131834;
	}
}

.profits-content-cost {
	@extend .profits-content;

	> p {
		color: #a2aab3;
	}
}

.finance-details tr > td:last-child {
	padding-left: 30px;
	@include mq("tablet", max) {
		padding-left: 45px;
	}
}

.financial-overview-heading {
	font-family: "DM Sans";
	font-weight: 400;
	font-size: 14px;
	color: #83879b;
	text-align: left;
}

.financial-overview-value {
	font-family: "DM Sans";
	font-weight: 700;
	font-size: 16px;
	text-align: right !important;
	color: #174486;
}

.financial-cost-heading {
	@extend .financial-overview-heading;
	color: #a2aab3;
}

.chart{
  position:relative;
}
.actual-cost-percentage {
	font-weight: 700;
	font-size: 20px;
	color: #131834;
	position: absolute;
	top: 63%;
	left: 51%;
	transform: translate(-50%, -50%);
}

.actual-cost {
	@extend .financial-cost-heading;

	> span {
		margin-right: 6px;
		width: 8px;
		height: 8px;
		background: #b9ddfc;
		border-radius: 50%;
		display: inline-block;
	}

}

.total-cost {
	@extend .financial-cost-heading;

	> span {
		margin-right: 6px;
		width: 8px;
		height: 8px;
		background: #E9E8ED;
		border-radius: 50%;
		display: inline-block;

	}


}

.cost-value {
	font-weight: 700;
	font-size: 18px;
	color: #131834;
	text-align: left;
}

.financial-cost-value {
	@extend .financial-overview-value;
	color: #131834;
	font-weight: 500;
}

.financial-construction-heading {
	@extend .financial-overview-heading;
	color: #131834;
}

.dashed-border {
	border-top: 1px dashed #a2aab3;
	opacity: 0.4;
	margin: 20px 18px 18px;
}

.nav-link.active {
	z-index: 1;
	border-radius: 1em 1em 0em 0em !important;
	background-color: #174486 !important;
	color: #fff !important;
	border: 1px solid transparent !important;
	margin-left: 0;
	font-weight: 700;

	&::before {
		content: "";
		width: 1em;
		height: 0.75em;
		background: #174486;
		position: absolute;
		left: -0.95em;
		bottom: -1px;
	}

	&::after {
		content: "";
		width: 1em;
		height: 0.75em;
		background: #174486;
		position: absolute;
		right: -0.95em;
		bottom: -1px;
	}
}

.nav-tabs {
	border-bottom: 1px solid #174486 !important;
	border-radius: 16px 16px 0px 0px;
	background-color: #f9f9fb;
}

.nav-tabs .nav-item {
	margin-top: 1px;
	margin-bottom: 0px !important;
}

.nav-item {
	position: relative;
	left: 0;
	margin-left: -1px;
}

.nav-link {
	z-index: 2;
	padding: 0.75em 2em !important;
	border-bottom-left-radius: 1em;
	border-bottom-right-radius: 1em;
	background-color: #f9f9fb;
	color: #83879b;
	line-height: 29px;
	position: relative;

	&:hover {
		background-color: #f5f5f5;
		border-top-left-radius: 1em !important;
		border-top-right-radius: 1em !important;
		border: 1px solid transparent !important;
		color: #83879b;
	}	
}

//li:first-child {
//  .nav-link {
//    &:before {
//      display: none;
//    }
//  }
//}
li:last-child {
	.nav-link {
		&:after {
			display: none;
		}
	}
}

.nav-wrapper {

	display: flex;
	justify-content: center;

	.nav-link.active {
		border-radius: 16px !important;
		border: 0 !important;

		&::before {
			display: none;
		}

		&::after {
			display: none;
		}
	}

	.nav-tabs {
		border-radius: 16px;
	}

	.nav-link {
		padding: 10px 12px !important;
		border-radius: 16px;

		&:hover {
			border: 0 !important;
		}

		@include mq("tablet", max) {
			padding: 10px 21px !important;
		}
	}
}

.development_details {
	border: 1px solid #e9e8ed;
	border-radius: 16px;
	padding: 12px;
	@include mq("tablet", max) {
		margin: 10px;
	}

	&__heading {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 14px;
		color: #131834;
		text-align: left;
		margin-bottom: 3px;
	}

	&__value {
		font-family: "DM Sans";
		font-weight: 700;
		font-size: 18px;
		color: #174486;
		text-align: left;
		margin-bottom: 0;
	}
}

.development-cost-section {
	position: relative;

	.document-section {
		@include mq("tablet", max) {
			position: absolute;
			top: 420%;
		}

	}
}

.documentation-list {
	border: 1px solid #e9e8ed;
	border-radius: 16px;
	padding: 16px;
	margin: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__doc-list {
		font-family: "DM Sans";
		font-weight: 700;
		font-size: 14px;
		color: #131834;
	}

	&__file-count {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 14px;
		color: #a2aab3;
		text-align: left;
	}

	&__upload-type {
		@extend .documentation-list__file-count;
		font-weight: 500px;
	}
}

.loan-details {

	tr:first-child {
		background: #f9f9fb;


		> td {
			font-family: "DM Sans";
			font-weight: 500;
			font-size: 14px;
			color: #a2aab3;
			padding: 0 5px;
			@include mq("tablet", max) {
				padding: 0 20px;
			}
		}
	}

	tr:last-child {
		> td {
			font-family: "DM Sans";
			font-weight: 400;
			font-size: 14px;
			color: #131834;
			padding: 20px 5px;
			@include mq("tablet", max) {
				padding: 20px;
			}
		}
	}

	tr > td:first-child {
		width: 50px;
	}
}

.loan-details tbody {
	display: block;
	border: 1px solid #E9E8ED;
	border-radius: 8px;
	margin-bottom: 10px;
	@include mq("tablet", max) {
		margin: 18px;
	}

}

.loan-details tr:first-child td:first-child {
	border-top-left-radius: 8px;
}

.loan-details tr:first-child td:last-child {
	border-top-right-radius: 8px;
}

/* ---- tool tip ------- */

span.tool-simbol {
	position: relative;
	left: -8px;
	/* right: 10px; */
	font-size: 9px;
	top: -3px;
	color: #a2aab3;
}

.dashboard-content__sub-section {
	font-weight: 700 !important;
	font-size: 18px !important;
}

.budget-breakdown-tab .nav-link {
	font-weight: 400;
	line-height: 150%;
}

.budget-breakdown-tab .dashboard-content__section-border p {
	font-weight: 400;
	font-size: 14px;
	line-height: 150%;
	color: #131834;
}

.budget-breakdown-tab .financial-overview-value {
	font-weight: 700 !important;
	font-size: 16px !important;
}

.budget-breakdown-tab .financial-overview-value.percentage {
	color: #131834 !important;
}

.budget-breakdown-tab .financial-overview-value {
	color: #174486 !important;
}

.color-blue {
	color: #174486 !important;
	font-weight: 700 !important;
	font-size: 16px !important;
}

.color-black {
	color: #131834 !important;
	font-weight: 700 !important;
	font-size: 16px !important;
}

.bg-color-gray {
	background: #E1E7EB !important;
}

.bg-color-purpal {
	background: #CED1F4 !important;
}

.bg-color-blue {
	background: #C0D1E6 !important;
}

.bg-color-light-gray {
	background: #F9F9FB !important;
}

.bg-color-golden {
	background: #EBE8E1 !important;
}

/* Code by SD */

.new-border::before {
	left: 9px !important;
}

.new-borderr::before {
	left: 9px !important;
}

.new-borderrr::before {
	left: 0px !important;
}

.pad-rig {
	padding-right: 0px !important;
}

.pad-bot {
	padding-bottom: 30px;
}

.color-grey {
	color: #A2AAB3 !important;
}

.cost-css {
	font-family: 'DM Sans';
	font-weight: 700 !important;
	font-size: 18px !important;
	line-height: 150%;
	color: #174486 !important;

}

.no-border {
	border: none !important;
	padding-top: 0px !important;
	padding-bottom: 0px !important;

}

/*.dashboard-content__sub-section{
  margin-bottom:28px !important;
}*/

@media only screen and (max-width: 767px) {
	.dashboard-content__sub-section {
		margin-bottom: 18px !important;
	}
	.pad-bot {
		padding-bottom: 15px;

	}	
	.proforma-tt {
		margin-left: -170px !important;
	}
	.mob-link {
		padding: 0.50em 1em !important;
		font-size: 14px !important;
	}
	.mob-link .tooltipss {
		font-size: 14px !important;
	}
	.dashboard-content p.project_name[data-v-ed558de2] {
		margin-left: 0px;
	}
	.actual-cost-percentage {
		top: 61%;
	}
}

.unit-rate {
	margin-left: -157px !important;
}

.unit-rate::after {
	left: 150px !important;
}

.develop-tt {
	margin-left: -90px !important;
}

.develop-tt::after {
	left: 82px !important;
}

.dashboard-content p.project-dashboard.pdash[data-v-ed558de2] {
	margin-left: 0px !important;
	text-align: left;
}

.dashboard-content p.project_name[data-v-ed558de2] {
	margin-left: 0px;
}

#pills-tabContent .financial-cost-value {
	color: #131834 !important;
}

.wrap-finace-view {
	margin-top: -16%;
	position: relative;
}

.align-items-center.finacal-bud {
	margin-top: -1rem !important;
}

.financial-overview .dashboard-content__sub-section {
	margin-bottom: 20px !important;
}

@media (max-width: 767px) {
	.financial-overview .scroll-tabs-financial {
		width: 100%;
		padding-top: 10px;
	}	
	.financial-overview .scroll-tabs-financial {
		padding-top: 77px;
		margin-top: -95px;
	}
	.scroll-tabs-financial .tooltipss .tooltiptext {
		white-space: normal;
		width: 150px;
		margin-left: -100px !important;
	}
	.scroll-tabs-financial .tooltipss .tooltiptext::after {
		left: 92px;
	}
}

span.tooltiptext.finan {
	width: 200px;
}

p.mb-0.documentation-list__doc-list.tooltipss.fina {
	font-weight: 700;
}


</style>
