<template>
  <div>
    <canvas ref="chartCanvas"></canvas>
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import Chart from 'chart.js/auto';

export default {
  props: {
    jsonData: {
      type: Object,
      required: true,
    },
    legend: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const chartCanvas = ref(null);
    const chartInstance = ref(null); // Use a ref to track the chart instance
    const chartData = {
      labels: [],
      datasets: [],
    }; // Use const to declare chartData

    const chartOptions = {
      plugins: {
        legend: {
          display: props.legend, // Use the 'legend' prop to conditionally show/hide the legend
        },
      },     
    };

    onMounted(() => {
      // Set different canvas size based on screen size (mobile view)
      if (window.innerWidth < 600) {
        chartCanvas.value.width = window.innerWidth; // Adjust as needed
        chartCanvas.value.height = window.innerWidth; // Adjust as needed
      }
      chartInstance.value = new Chart(chartCanvas.value, {
        type: 'bar',
        data: chartData,
        options: chartOptions,
      });
    });

    watch(
      () => props.jsonData,
      () => {
        try {
          if (props.jsonData && props.jsonData.contents && props.jsonData.contents.series) {
            chartData.labels = props.jsonData.contents.series[0].seriesData.map((data) => data.x);
            chartData.datasets = props.jsonData.contents.series.map((series) => {
              return {
                label: series.title,
                data: series.seriesData.map((data) => data.y),
                backgroundColor: series.color,
              };
            });

            if (chartInstance.value) {
              chartInstance.value.data = chartData;
              chartInstance.value.update(); // Call the update method only if the chartInstance is available
            }
          }
        } catch (error) {
          console.error('Error in watcher callback:', error);
        }
      },
      { immediate: true }
    );

    return {
      chartCanvas,
    };
  },
};
</script>

<style>
canvas {
  width: 100%;
  height: 100%;
}
</style>
