<template>
  <div class="mt-3 sales-and-leasing mb-4">
    <div class="tabs-scroll">
      <ul class="nav nav-tabs" id="costAndBudgetTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="general-tab"
            data-toggle="tab"
            href="#general"
            role="tab"
            aria-controls="general"
            aria-selected="true"
            >General</a
          >
        </li>
        <li class="nav-item" v-if="this.permission != 'Contractor' && this.permission != 'Lessee/Buyer' && this.permission != 'Consultant'">
          <a
            class="nav-link"
            id="rent-tab"
            data-toggle="tab"
            href="#rent"
            role="tab"
            aria-controls="rent"
            aria-selected="false"
            >Rent Roll/Sales chart
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="costAndBudgetTabContent">
      <div
        class="tab-pane fade show active"
        id="general"
        role="tabpanel"
        aria-labelledby="general-tab"
      >
     
        <div class="row align-items-start">
          <div class="col-12 col-lg-8 mt-4 mt-md-0" v-if="this.permission != 'Contractor' && this.permission != 'Lessee/Buyer' &&  this.permission != 'Consultant'">
            <div class="row align-items-start">
              <div class="col-12 col-lg-6">
                <div class="dashboard-content__section-border table-align">
                  <p class="dashboard-content__sub-section sales-first-sec">Sales/Leasing Summary</p>
                  <div class="ml-4 mr-4">
                    <div class="d-flex justify-content-between">
                      <p class="leasing-summary-heading">Develoment will be</p>
                      <p class="leasing-summary-value" v-if="!isLoding">{{jsonOutput.rows[8].cells[5].displayValue}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="leasing-summary-heading">Total Development is </p>
                      <p class="leasing-summary-value" v-if="!isLoding">{{jsonOutput.rows[9].cells[5].displayValue}} {{jsonOutput.rows[9].cells[6].displayValue}}</p>
                    </div>
                    <p class="leasing-summary-heading text-left">
                      Firm and Tentative
                    </p>
                    <div class="d-flex justify-content-between">
                      <p class="leasing-summary-heading1 col-lgrey">Sold/Leased</p>
                      <p class="leasing-summary-value" v-if="!isLoding">{{jsonOutput.rows[10].cells[5].displayValue}} sq.ft.</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="leasing-summary-heading1 col-lgrey">%Sold/Leased</p>
                      <p class="leasing-summary-value" v-if="!isLoding">{{jsonOutput.rows[11].cells[5].displayValue}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="leasing-summary-heading1 col-lgrey">Sales Revenue</p>
                      <p class="leasing-summary-value" v-if="!isLoding">{{jsonOutput.rows[12].cells[5].displayValue}}</p>
                    </div>
                    <div class="d-flex justify-content-between">
                      <p class="leasing-summary-heading1 col-lgrey">Net Operating Income</p>
                      <p class="leasing-summary-value" v-if="!isLoding">{{jsonOutput.rows[13].cells[5].displayValue}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6 mt-3 mt-md-0">
                <div class="dashboard-content__section-border">
                  <p class="dashboard-content__sub-section sales-first-sec">% Sold/Leased</p>
                  <div class="ml-2 mr-2">
                    <div style="
                          position: relative;
                          height: 10vw; 
                          width:10vw;
                          margin: 0 auto;
                        ">
                        <canvas id="soldChart"></canvas>
                    </div>
                    <div class="d-flex justify-content-evenly firm-aligns">
                      <div class="col-4 d-flex justify-content-center align-items-center flex-column">
                        <p class="firm-percentage mb-1"><span></span>Firm</p>
                        <p class="sold-leased-percentage" v-if="!isLoding">{{jsonOutput.rows[16].cells[7].displayValue}}</p>
                      </div>
                      <div class="col-4 d-flex justify-content-center align-items-center flex-column">
                        <p class="tentative-percentage mb-1">
                          <span></span>Tentative
                        </p>
                        <p class="sold-leased-percentage" v-if="!isLoding">{{jsonOutput.rows[17].cells[7].displayValue}}</p>
                      </div>
                      <div class="col-4 d-flex justify-content-center align-items-center flex-column">
                        <p class="none-percentage mb-1"><span></span>None</p>
                        <p class="sold-leased-percentage" v-if="!isLoding">{{jsonOutput.rows[18].cells[7].displayValue}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="dashboard-content__section-border">
                  <p class="dashboard-content__sub-section mar-25 mb-2">Sales Revenue</p>
                  <div class="row mb-3">
                    <div class="col-12 col-lg-6">
                      <div class="ml-5 mr-5 revenue pt-0" >                       
                        <BarChart :jsonData="chart.saleRevenue" :legend="showLegend" v-if="!isLoadingSaleRevenue" />                       
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 revenue-details">
                      <div class="ml-4 mr-4 mt-3">
                        <div class="d-flex justify-content-between mt-3">
                          <p class="financial-cost-heading col-lgrey">Firm
                            <a class="tooltipss"><img src="@/assets/images/vector.png" />
                              <span class="tooltiptext">An accepted offer with all subjects removed.</span>
                            </a>
                          </p>

                          <p class="leasing-summary-heading" v-if="!isLoding">{{jsonOutput.rows[16].cells[5].displayValue}}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="financial-cost-heading col-lgrey">Tentative
                            <a class="tooltipss"><img src="@/assets/images/vector.png" />
                              <span class="tooltiptext">An accepted offer with subjects NOT yet removed.</span>
                            </a>
                          </p>
                          <p class="leasing-summary-heading" v-if="!isLoding">{{jsonOutput.rows[17].cells[5].displayValue}}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="financial-cost-heading col-lgrey">None
                            <a class="tooltipss"><img src="@/assets/images/vector.png" />
                              <span class="tooltiptext">An accepted offer with all subjects removed.</span>
                            </a>
                          </p>
                          <p class="leasing-summary-heading" v-if="!isLoding">{{jsonOutput.rows[18].cells[5].displayValue}}</p>
                        </div>
                        <div class="dashed-border ml-0 mr-0 sale-border"></div>
                        <div class="d-flex justify-content-between">
                          <p class="financial-cost-heading col-lgrey">
                            Total Projected <br>
                          Sales Revenue
                          </p>
                          <p class="development_details__value total-value" v-if="!isLoding">
                            {{jsonOutput.rows[19].cells[5].displayValue}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-12">
                <div class="dashboard-content__section-border">
                  <p class="dashboard-content__sub-section mar-25 mb-2">Net Operating Income</p>
                  <div class="row mb-3">
                    <div class="col-12 col-lg-6">
                      <div class="ml-5 mr-5 noiChart-bar mt-0">
                        <BarChart :jsonData="chart.netIncome" :legend="showLegend" v-if="!isLoadingNetIncome" />
                      </div>
                    </div>
                    <div class="col-12 col-lg-6 revenue-details">
                      <div class="ml-4 mr-4 mt-3">
                        <div class="d-flex justify-content-between mt-3">
                          <p class="financial-cost-heading col-lgrey">Firm
                            <a class="tooltipss"><img src="@/assets/images/vector.png" />
                              <span class="tooltiptext">An accepted offer with all subjects removed.</span>
                            </a>
                          </p>
                          <p class="leasing-summary-heading" v-if="!isLoding">{{jsonOutput.rows[16].cells[6].displayValue}}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="financial-cost-heading col-lgrey">Tentative
                            <a class="tooltipss tool"><img src="@/assets/images/vector.png" />
                              <span class="tooltiptext">An accepted offer with subjects NOT yet removed.</span>
                            </a>
                          </p>
                          <p class="leasing-summary-heading" v-if="!isLoding">{{jsonOutput.rows[17].cells[6].displayValue}}</p>
                        </div>
                        <div class="d-flex justify-content-between">
                          <p class="financial-cost-heading col-lgrey">None
                            <a class="tooltipss tool"><img src="@/assets/images/vector.png" />
                              <span class="tooltiptext">No agreement or interest at this time.</span>
                            </a>
                          </p>
                          <p class="leasing-summary-heading" v-if="!isLoding">{{jsonOutput.rows[18].cells[6].displayValue}}</p>
                        </div>
                        <div class="dashed-border ml-0 mr-0 sale-border"></div>
                        <div class="d-flex justify-content-between">
                          <p class="financial-cost-heading col-lgrey">
                            Total Projected Annual <br />
                             Net Operating Income
                          </p>
                          <p class="development_details__value total-value" v-if="!isLoding">
                            {{jsonOutput.rows[19].cells[6].displayValue}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-4 my-4 mt-md-0" :class="{ 'd-none': permission == 'Contractor' ||   permission == 'Lessee/Buyer' || permission == 'Consultant'}">
            <div class="dashboard-content__section-border" style="height: 100%;">
              <div class="dashboard-content__section-border relative ml-3 mr-3 mt-3 mb-3" v-if="this.permission == 'Realtor'">
                <p class="dashboard-content__sub-section1 back-gold documents">Realtor access</p>
                <div class="dashboard-content__sub-section-content">
                  <div class="documentation-list mb-3">
                      <button class="bg-transparent" @click="redirectToLink(realtorDashbord)">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="document-list-icon mr-2"></div>
                          <div class="">
                            <p class="mb-0 documentation-list__doc-list"> Sales/Leasing Portal</p>                                  
                          </div>
                        </div>
                      </button>
                  </div>
                </div>
              </div>
              <div class="dashboard-content__section-border ml-3 mr-3">
                <p class="dashboard-content__sub-section sales-first-sec">Listing Info</p>
                <div class="dashboard-content__sub-section-content ml-3 mr-3">
                  <p class="dashboard-content__super-sub-section">Realtor</p>
                  <div class="d-flex justify-content-center">
                    <div class="profil-pic-parent">
                      <div class="profile-img-wrapper coller">
                        <img v-if="!isLodingDirectory"
                          :src="directory.images[0]"
                          loading="lazy"
                          alt=""
                          class="profil-pic"
                        />
                      </div>
                    </div>
                  </div>
                  <table class="profile-details sales-profile-details sales-table">
                    <tr>
                      <td>Company</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[1].cells[7].displayValue }}</td>
                    </tr>
                    <tr>
                      <td>Contact</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[2].cells[7].displayValue }}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[3].cells[7].displayValue }}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[4].cells[7].displayValue }}</td>
                    </tr>
                    <tr>
                      <td>Street Address</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[6].cells[7].displayValue }}</td>
                    </tr>
                    <tr>
                      <td>City, Province</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[7].cells[7].displayValue }}</td>
                    </tr>
                    <tr>
                      <td>Postal Code</td>
                      <td v-if="!isLodingDirectory">{{ directory.info.rows[8].cells[7].displayValue }}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="dashboard-content__section-border ml-3 mr-3 mt-3 mb-3">
              <p class="dashboard-content__super-sub-section mb-2">Marketing
                <span class="tooltipss">
                  <a class="tooltipss tool"><img src="@/assets/images/vector.png" />
                      <span class="tooltiptext">All contracts that relate to leasing and sale agreements for the development.</span>
                    </a> </span>
                </p>
                <div class="dashboard-content__sub-section-content">
                  <div class="documentation-list mb-3">
					          <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[31].contents.shortcutData[0].hyperlink.url)">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="document-list-icon mr-2"></div>
                          <div class="">
                            <p class="mb-0 documentation-list__doc-list">
                              Documents
                            </p>                            
                          </div>
                        </div>
                  </button>
                  </div>
                  <div class="documentation-list mb-3">
                    <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[38].contents.shortcutData[0].hyperlink.url)">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="document-list-icon mr-2"></div>
                          <div class="">
                            <p class="mb-0 documentation-list__doc-list">
                              Kilgard Business Park-Website
                            </p>                                
                          </div>
                        </div>
                    </button>
                  </div>
                </div>
              </div>

              <div class="dashboard-content__section-border relative ml-3 mr-3 mt-3 mb-3">
                <p class="dashboard-content__sub-section1 back-gold documents">Documents</p>
                <div class="dashboard-content__sub-section-content">
                  <div class="documentation-list mb-3">
                      <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[96].contents.shortcutData[0].hyperlink.url)">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="document-list-icon mr-2"></div>
                          <div class="">
                              <p class="mb-0 documentation-list__doc-list">
                              Contracts
                              <a class="tooltipss"><img src="@/assets/images/vector.png" />
                                  <span class="tooltiptext pos-tool">All contracts that relate to leasing and sale agreements for the development.</span>
                                </a>
                            </p>                                  
                          </div>
                        </div>
                      </button>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="col-12 my-4" v-if="this.permission == 'Contractor' || this.permission == 'Lessee/Buyer'|| this.permission == 'Consultant'">
            <div class="dashboard-content__section-border">
              <div class="row align-items-start">
                <div class="col-12 col-lg-6">
                  <p class="dashboard-content__sub-section sales-first-sec">Listing Info</p>
                  <div class="dashboard-content__section-border ml-3 mr-3 mb-3">
                    <p class="dashboard-content__super-sub-section">Realtor</p>
                    <div class="dashboard-content__sub-section-content ml-3 mr-3">
                      <div class="d-flex justify-content-center">
                        <div class="profil-pic-parent">
                          <div class="profile-img-wrapper coller">
                            <img v-if="!isLodingDirectory"
                              :src="directory.images[0]"
                              loading="lazy"
                              alt=""
                              class="profil-pic"
                            />
                          </div>
                        </div>
                      </div>
                      <table class="profile-details sales-profile-details sales-table">
                        <tr>
                          <td>Company</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[1].cells[7].displayValue }}</td>
                        </tr>
                        <tr>
                          <td>Contact</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[2].cells[7].displayValue }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[3].cells[7].displayValue }}</td>
                        </tr>
                        <tr>
                          <td>Phone</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[4].cells[7].displayValue }}</td>
                        </tr>
                        <tr>
                          <td>Street Address</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[6].cells[7].displayValue }}</td>
                        </tr>
                        <tr>
                          <td>City, Province</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[7].cells[7].displayValue }}</td>
                        </tr>
                        <tr>
                          <td>Postal Code</td>
                          <td v-if="!isLodingDirectory">{{ directory.info.rows[8].cells[7].displayValue }}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-6">
                  <div class="dashboard-content__section-border ml-3 mr-3 mt-3 mb-3">
                  <p class="dashboard-content__super-sub-section mb-2">Marketing
                    <span class="tooltipss">
                      <a class="tooltipss tool"><img src="@/assets/images/vector.png" />
                          <span class="tooltiptext">All contracts that relate to leasing and sale agreements for the development.</span>
                        </a> </span>
                    </p>
                    <div class="dashboard-content__sub-section-content">
                      <div class="documentation-list mb-3">
                        <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[31].contents.shortcutData[0].hyperlink.url)">
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="document-list-icon mr-2"></div>
                              <div class="">
                                <p class="mb-0 documentation-list__doc-list">
                                  Documents
                                </p>                            
                              </div>
                            </div>
                      </button>
                      </div>
                      <div class="documentation-list mb-3">
                        <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[38].contents.shortcutData[0].hyperlink.url)">
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="document-list-icon mr-2"></div>
                              <div class="">
                                <p class="mb-0 documentation-list__doc-list">
                                  Kilgard Business Park-Website
                                </p>                                
                              </div>
                            </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="rent"
        role="tabpanel"
        aria-labelledby="rent-tab"
      >
        
      <div class="row mt-3 mb-0 mb-lg-3 sales-tab2">
      <div class="col-12">
        <div class="dashboard-content__section-border">
          <!-- <p class="dashboard-content__sub-section">Sample Text</p> -->
          <div class="dashboard-content__sub-section-content table-sec">
            <div class="ml-2 mr-2 consultant-table-scroll sales-scroll">
              <div v-html="chart.rentRoll" class="embeded"></div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<script lang="js">
import BarChart from '@/components/BarChart.vue';
import { connectUrl } from '@/helpers/constants'
import Chart from 'chart.js/auto';
import { mapState } from 'vuex'
export default {
  name: 'SalesandLeasing',
  components: {
    BarChart     
  },
  data() {
		return {
      realtorDashbord:'',
      jsonOutput:[],
      directory:[],
      dashboard:[],
      isLodingDoc:true,
			isLoding:true,
      isLoadingSaleRevenue:true,
      isLodingDirectory:true,
      isLoadingNetIncome:true,
      chart:{
        saleRevenue:null,
        netIncome:null,
        rentRoll:''
      },
			smartsheetUrl:connectUrl,
      showLegend: false,
		}
	},
  computed: {		
		...mapState(['workspace', 'projectId', 'permission'])
	},
  mounted() {
    if(this.workspace.folders[this.projectId].folders[0].folders[1].folders){
      this.realtorDashbord = this.workspace.folders[this.projectId].folders[0].folders[1].folders[0].sights[0].permalink
    }else{
      this.realtorDashbord = this.workspace.folders[this.projectId].folders[0].folders[1].sights[0].permalink
    }
  },
  methods: {
		getSmartsheet() {
      fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[3].sights[2].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){              
              this.chart.saleRevenue = result.data.widgets[0] 
              this.isLoadingSaleRevenue = false
            }
      });

      fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[3].sights[3].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){             
              this.chart.netIncome = result.data.widgets[0] 
              this.isLoadingNetIncome = false
            }
      }); 
      
			fetch(`${this.smartsheetUrl}get-sheet?id=${this.workspace.folders[this.projectId].folders[1].folders[3].sheets[0].id}`)
      .then((res) => res.json())
			.then((result) => {
				if(result.status == 'success'){
          this.isLoding = false
          this.jsonOutput = result.data		
          if(this.permission != 'Contractor' && this.permission != 'Lessee/Buyer' && this.permission != 'Consultant')		{	        
           this.soldLeasedChart(result.data) 
          }         
				}
			});	

      fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[3].sights[0].id}`)
      .then((res) => res.json())
      .then((result) => {
        if(result.status == 'success'){
          this.chart.rentRoll = result.data.widgets[0].contents.htmlContent
        }
      }); 

      fetch(`${this.smartsheetUrl}sales-leasing-directory?id=${this.workspace.folders[this.projectId].folders[1].folders[0].sheets[1].id}`)
      .then((res) => res.json())
      .then((result) => {
        if(result.status == 'success'){               
          this.directory = result.data
          this.isLodingDirectory = false
        }
      }); 
      
      if(this.workspace.folders[this.projectId].folders[0].folders[0].sights && this.workspace.folders[this.projectId].folders[0].folders[0].sights[0]){
        fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[0].folders[0].sights[0].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.isLodingDoc = false
            this.dashboard = result.data					
          }
        });
      }
		},
		redirectToLink(link){
			window.open(link, '_blank')		
		},
    soldLeasedChart(data){
        const soldLeasedChart = document.getElementById('soldChart');
        const soldChart = new Chart(soldLeasedChart, {
        type: 'doughnut',
        data: {
          labels: ["Firm",	"Tentative", "None"],
          datasets: [{
              label: '# of Votes',
              data: [
                data.rows[16].cells[7].value,
                data.rows[17].cells[7].value,
                data.rows[18].cells[7].value
              ],
              backgroundColor: [
                'rgb(67,143,254)',
                  'rgb(195,230,252)',
                  'rgb(23,68,134)'
              ],
              borderColor: [
                'rgb(67,143,254)',
                'rgb(195,230,252)',
                  'rgb(23,68,134)'

              ],
              borderWidth: 1,
              cutout:"60%"
          }]
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false
            }
          },
        }
      });
    }  
   
	},
	created() {
		this.getSmartsheet();
	}, 
}
</script>
<style lang="scss">
@import "@/assets/styles/_mixin.scss";
// a {
//   font-size:25px;
//   color: #0bd;
//   text-decoration: none;
// }
.tooltip {
  display: inline;
  position: relative;
}
.tooltip:hover:after{
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #444;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  margin: -82px auto 0;
  font-size: 16px;
  padding: 13px;
  width: 220px;
}
.tooltip:hover:before{
  border: solid;
  border-color: #444 transparent;
  border-width: 12px 6px 0 6px;
  content: "";
  left: 45%;
  bottom: 30px;
  position: absolute;
}

.sales-table {
	margin-top: 16px;
}

.sales-tab2 {
  tbody tr:nth-child(1) > td {

    border: 1px solid #e9e8ed;
    color: #000000 !important;
    text-align: center;
  }
  tbody tr:last-child > td {
    background: #F2F2F2 !important;
  }
  .ml-2.mr-2.consultant-table-scroll.sales-scroll {
    overflow: auto;
  // height: 6px;
  // left: 18px;
  // bottom: 8px;
  // background: #E9E8ED;
  // border-radius: 4px;
}
}
.profile-img-wrapper {
    // width: 9.375em !important;
    // height: auto !important;
    border-radius: 0 !important;
    width: 130px;
    height: 75px;
    object-fit: cover;
    margin-bottom: 15px;
}
.profile-img-wrapper.coller{
  width: 130px !important;
  height: 75px;
}
::-webkit-scrollbar{
        height: 6px;
        left: 10px;
        bottom: 8px;
        // background: #E9E8ED;
        // border-radius: 4px;
    }
    ::-webkit-scrollbar-thumb:horizontal{
      background: #E9E8ED;
      border-radius: 4px;
    }
.dashboard-content{
.tab-content {
  margin-top: 25px;
}
}



.consultant-table tbody tr > td {
    color: red;
}
.dashboard-content {
  &__super-sub-section {
    font-family: "DM Sans";
    font-weight: 700;
    font-size: 18px;
    text-align: left;
    padding: 18px 0 0 18px;
    color: #131834;
	  // overflow: auto;
  }
  &__sub-section-heading {
    @extend .dashboard-content__super-sub-section;
    font-size: 18px;
    padding: 0 18px;
	  overflow: auto;
  }
}
.sales-profile-details tr > td:last-child {
  @include mq("tablet", max) {
    padding-left: 10px;  
  }
}
.firm-percentage {
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  color: #818da2;
  line-height: 150%;
  > span {
    width: 8px;
    height: 8px;
    background: #438ffe;
    border-radius: 16px;
    margin-right: 6px;
    display: inline-block;
  }
}

.tentative-percentage {
  @extend .firm-percentage;
  > span {
    background: #c3e6fc;
  }
}
.none-percentage {
  @extend .firm-percentage;
  > span {
    background: #2a519d;
  }
}
.sold-leased-percentage {
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 16px;
  color: #162848;
  margin-left: 14px;
  line-height: 150%;
}
.leasing-summary-heading {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #131834;
  line-height: 150%;
}
.leasing-summary-heading.text-left {
  font-weight: 700;
font-size: 16px;
border-bottom: 1px solid #E9E8ED;
line-height: 150%;

  }
.leasing-summary-value {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #174486;
  line-height: 150%
}
.leasing-summary-heading1 {
  @extend .leasing-summary-heading;
  // color: #a2aab3;
}
.revenue-details {
  border-left: 1px solid #e9e8ed;
  @include mq("tablet", max) {
    border-left: 0;
  }
  > div {
    @include mq("tablet", max) {
      border-top: 1px solid #e9e8ed;
    }
  }
}
.total-value {
  text-align: right !important;
  @include mq("tablet", max) {
    margin-top: -3px;
  }
}

span.tool-simbol {
    position: relative;
    left: -8px;
    /* right: 10px; */
    font-size: 9px;
    top: -3px;
    color: #a2aab3;
}
// .sales-table {
//     table-layout: fixed;
//     width: 100%;
//     /* text-align: center; */
// }

.sales-scroll .consultant-table thead tr > th {
    // min-width: 160px;
    min-width: 127px;
    background: #83879B;
    color: #FFFFFF;
    line-height: 150%;
}
.tenant-table tbody tr:nth-child(1) > td {
    text-align: left !important;
}
.sales-scroll .consultant-table thead tr > th:first-child {
min-width: 60px;
}
.ml-5.mr-5.revenue {
  width: 88%;
  margin: 0px !important;
    margin-left: 0px;
  padding-top: 20px;
  margin-left: 25px !important;
}
.dashboard-content__sub-section-heading.sale-opt {
  font-size: 16px !important;
}

.financial-cost-heading.tooltipss, .financial-cost-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #1F254A;
}
.relative{
  position:relative;
}
.dashboard-content__sub-section1 {
    font-family: "DM Sans";
    font-weight: 700!important;
    font-size: 18px;
    color: #131834;
    padding: 16px 16px 16px 24px;
    border-radius: 16px 16px 0px 0px;
    margin-bottom: 36px;
    text-align:left;
}
.dashboard-content__sub-section1::before {
    content: "";
    width: 100px;
    height: 100px;
    position: absolute;
    left: 0px;
    top: 16px;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml,%3Csvg width=%274%27 height=%2724%27 viewBox=%270 0 4 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M0 0C2.20914 0 4 1.79086 4 4V20C4 22.2091 2.20914 24 0 24V0Z%27 fill=%27%23174486%27/%3E%3C/svg%3E%0A');
}
// @media only screen and (max-width:574px){
//   .yellow{
//     background: rgba(254, 254, 133, 0.2);
//   }
// }
.yellow{
    background: rgba(254, 254, 133, 0.2);
  }
  .ml-5.mr-5.noiChart-bar {
    width: 98%;
    margin-right: 22px !important;
    margin-left: 9px !important;
    margin-top: 20px;
}
.back-gold{
  background-color: #EBE8E1 !important;
}
.flex-mar > div{
 margin-right: 43px;
}
.dashboard-content__sub-section.sales-first-sec {
    margin-bottom: 20px !important;
}
.firm-aligns .col-4{
  position:relative;align-items:flex-start!important;
}
.dashboard-content__sub-section.sales-first-sec{
  margin-bottom:10px!important;
}
.sales-and-leasing .dashboard-content__sub-section-heading{
  font-size: 16px;margin-bottom:5px
}
@media only screen and (max-width:767px){
  .flex-mar > div {
    margin-right: 37px;
}
.col-lgrey{
  color: #A2AAB3 !important;
}
}

@media only screen and (max-width: 767px){
  .dashboard-content .tab-content{margin-top:0;}
  p.dashboard-content__sub-section1.back-gold{margin-bottom: 10px;}  
}

.documents{
  margin-bottom: 10px;
}
.sales-table tr > td:first-child {
    width: 115px !important;
}
@media only screen and (min-width: 767px){
canvas#revenueChart {
  min-height: 188px !important;
}
#noiChart{
  min-height: 188px !important;
}
  .mar-25{
    margin-bottom: 25px !important;
  }
}



.table-align div p:nth-child(2){
  min-width: 90px;
  text-align: left !important;
}
.pos-tool{
  margin-left: -92px !important;
}
.pos-tool::after{
  margin-left: 29px !important;
}
.dashed-border.ml-0.mr-0.sale-border {
    margin-top: 5px;
}
.sales-table tr th:nth-child(3){
  min-width: 100px !important;
}
.sales-table tr th:nth-child(2){
  min-width: 100px !important;
}
.sales-table tr th:nth-child(10){
  min-width: 100px !important;
}
.sales-table tr th:nth-child(11){
  min-width: 100px !important;
}
.sales-table tr th:nth-child(12){
  min-width: 100px !important;
}
.sales-table tr th:nth-child(13){
  min-width: 100px !important;
}
.sales-table tr th:nth-child(14){
  min-width: 108px !important;
}
.sales-table tr th:nth-child(15){
  min-width: 90px !important;
}
.sales-table tr th:nth-child(21){
  min-width: 125px !important;
}

</style>