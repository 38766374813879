<template>
	<div class="tab-item-child">
		<div class="w-form auth-wrapper">
			<form id="wf-form-Profile-Form" name="wf-form-Profile-Form" data-name="Profile Form" method="get"
				  class="user-profile-form">
				<div class="form-group"><label for="Email-2" class="form-label">Login Email</label>
					<input type="email" v-model="customerDetails.email" class="rev-form-control" maxlength="256"
						   name="Email" data-name="Email" placeholder="Your email" id="Email-2" required="">
				</div>
				<div class="form-group"><label for="First-Name" class="form-label">First Name</label>
					<input type="text" v-model="customerDetails.firstname" class="rev-form-control w-input"
						   maxlength="256" name="First-Name" data-name="First Name" placeholder="First Name"
						   id="First-Name" required="">
				</div>
				<div class="form-group"><label for="Last-Name" class="form-label">Last Name</label>
					<input type="text" v-model="customerDetails.lastname" class="rev-form-control w-input"
						   maxlength="256" name="Last-Name" data-name="Last Name" placeholder="Last Name" id="Last-Name"
						   required="">
				</div>
				<div class="form-group"><label for="Phone" class="form-label">Phone</label>
					<div class="input-group">
						<input type="tel" v-model="customerDetails.phone"
							   class="rev-form-control input-group-item w-input" maxlength="256" name="Phone"
							   data-name="Phone" placeholder="Your Phone Number" id="Phone" required=""></div>
				</div>
				<div class="form-group">
					<label for="Company" class="form-label">Company</label>
					<input type="text" v-model="customerDetails.company" class="rev-form-control w-input"
						   maxlength="256" name="Company" data-name="Company" placeholder="Your Company" id="Company"
						   required="">
				</div>
				<button type="button" class="btn btn-blue wd-100 mt-12 login-btn" @click="updateCustomer" :disabled="isButtonDisabled">
              		<div v-if="isLoading" class="spinner"></div>
  					<div v-else class="txt-white">Update Info</div>
				</button>				
			</form>			
		</div>
	</div>
</template>

<script lang="js">
import { mapActions} from 'vuex'
import * as types from '@/store/types'
import {useToast} from "vue-toastification";

export default {
	name: 'myaccount',
	components: {},
	data() {
		return {
			customerDetails: {
				email: "",
				firstname: "",
				lastname: "",
				company: "",
				phone: "",				
			},
			isButtonDisabled: false,
      		isLoading: false 
		}
	},	
	setup() {
		// Get toast interface
		const toast = useToast();

		// Make it available inside methods
		return {toast}
	},	
	created() {
		this.getUser();
	},	
	methods: {
		...mapActions({updateCustomerAPICall: types.UpdateCustomer_Action}),
		...mapActions({getCustomerAPICall: types.GetCustomer_Action}),

		updateCustomer() {			
			if (this.customerDetails.email == "") {
				this.toast.info("Please Enter the Email !");
			} else if (this.customerDetails.company == "") {
				this.toast.info("Please Enter the Company !");
			} else if (this.customerDetails.firstname == "" || this.customerDetails.lastname == "") {
				this.toast.info("Please Enter the Full Name !");
			} else if (this.customerDetails.phone == "") {
				this.toast.info("Please Enter the Phone !");
			} else {	
				 // Disable the button and set loading to true
				this.isButtonDisabled = true;
        		this.isLoading = true;			
				this.updateCustomerAPICall(JSON.stringify(this.customerDetails)).then(response => {
					if(response.message == 'success'){
						this.toast.success(response.notice); 
					}else{
						this.toast.error(response.notice); 
					}
					this.isButtonDisabled = false;
          			this.isLoading = false;          
				},error =>{
					this.isButtonDisabled = false;
          			this.isLoading = false;  
					this.toast.error(error.message);
				})
			}
		},

		getUser() {
			this.getCustomerAPICall().then(response => {
				if(response.message == 'success'){
					this.customerDetails = response.data
				}
			}, err => {
				console.log("Error" + err);
			})
		},

	},
}
</script>