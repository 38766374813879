import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import Store from '../store'
import * as types from '../store/types'

import ProjectOverview from '../views/Project-Overview'
import FinancialOverview from '../views/Financial-Overview'
import FinancialCostBreakdown from'../views/Financial-Cost-Breakdown'
import SalesandLeasing from '../views/Sales-and-Leasing'
import Construction from '../views/Construction'
import PreConstruction from '../views/Pre-Construction'
import PostConstruction from '../views/Post-Construction'
import FinancingLoan from '../views/Financing-Loan'
import UserProfile from '../views/UserProfile'
import Projects from '../components/userprofile/Projects.vue'
import MyAccount from '../components/userprofile/MyAccount.vue'
import Settings from '../components/userprofile/Settings.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/reveloper-frontend/',
    name: 'home',
    component: Home,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/project-overview',
    name: 'ProjectOverview',
    component: ProjectOverview,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/financial-overview',
    name: 'FinancialOverview',
    component: FinancialOverview,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/financial-cost-and-budget-breakdown',
    name: 'FinancialCostBreakdown',
    component: FinancialCostBreakdown,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/sales-and-leasing',
    name: 'SalesandLeasing',
    component: SalesandLeasing,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/construction',
    name: 'Construction',
    component: Construction,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/pre-construction',
    name: 'PreConstruction',
    component: PreConstruction,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/post-construction',
    name: 'PostConstruction',
    component: PostConstruction,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/financing-loan',
    name: 'FinancingLoan',
    component: FinancingLoan,
    meta:{      
      requireAuth: true,
      requireProject:true
    } 
  },
  {
    path: '/user-account/',
    name: 'userprofile',
    component: UserProfile, 
    children: [
      {
        path: '', // This is an empty path for the default child route
        name: "projects",
        component: Projects,    
        meta:{      
          requireAuth: true,          
        }      
      },   
      {
        path: "myaccount",
        name: "myaccount",
        component: MyAccount,
        meta:{      
          requireAuth: true,
        }
      },
      {
        path: "settings",
        name: "settings",
        component: Settings,
        meta:{      
          requireAuth: true,
        }
      }
    ]
  },
  
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/create-account',
    name: 'createAccount',
    component: () => import('../views/CreateAccount.vue')
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import('@/components/ForgetPassword.vue')
  },
  {
    path: '/reset-password/:slug',
    name: 'ResetPassword',
    component: () => import('@/components/ResetPassword.vue')
  },
    // catch all redirect to home page
    { path: '/:pathMatch(.*)*', redirect: '/login' }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
 //console.log(to);
  if (to.matched.some(record => record.meta.requireAuth)) { 
    if (to.meta.requireAuth && !Store.getters[types.SetAuth_Getter]) { 
      return {
          path: '/login'                
      }
    }       
    if(to.meta.requireProject && !Store.state.projectView){
      return {
          path: '/user-account'                
      }
    }
  }else{
    return
  }
});

export default router
