<template>
	<div class="my-project-grid" v-if="projectList.length">
		<div v-for="(project, index) in projectList" :key="`${index}`">
			<div class="project-item mt-3">
				<div class="project-img-wrapper" :style="{ backgroundImage: 'url(' + project.image + ')' }"></div>				
				<div class="project-detail-wrapper">
					<h6 class="project-name text-left" @click="selectProject(project.projectIndex, project.permission)">{{workspace.folders[project.projectIndex].name}}</h6>
					<p class="txt-sm mt-8 txt-grey text-left">{{ project.project.rows[6].cells[1].displayValue+' '+project.project.rows[7].cells[1].displayValue+' '+project.project.rows[8].cells[1].displayValue}}</p>
					<p class="text-left project-access">{{ project.permission }} access</p>
				</div>
			</div>
		</div>
	</div>
	<LogoLoder v-if="isProjectLoading"/>	

	<div class="col-lg-8 col-sm-6 mx-auto mt-5" v-if="!isAssignProject">
		<p>Currently you have no projects assigned in your project list.</p>			
		<p>If you would like access, please contact us at info@reveloper.ca to gain access to your specific project.</p>
	</div>
</template>


<script lang="js">
import { connectUrl } from '@/helpers/constants'
import {mapActions, mapMutations, mapState} from 'vuex'
import * as types from '@/store/types'
import LogoLoder from "@/components/LogoLoader.vue";

export default {
	name: 'projects',
	components: {LogoLoder},
	data() {
		return {
			projectList: [],
			isProjectCall: false,
			smartsheetUrl:connectUrl,
			isAssignProject:true,
			isProjectLoading:true
		}
	},
	created() {
		this.getProjectsAPI();
	},	
	computed: {		
		...mapState(['workspace', 'projectView', 'projects'])
	},	
	watch: {		
		workspace: 'displayProjects'		
	},
	methods: {
		...mapActions({
			getProjectsAPICall: types.GetProjects_Action,
		}),
		...mapActions({getWorkspaceAPI: types.GetWorkspace_Action}),

		...mapMutations({
			setProjectId: 'SetProjectId_Mutation', 	
			setPermission: 'SetPermission_Mutation',	
			setProjectView: 'SetProjectView_Mutation',
		}),

		async selectProject(projectIndex, permission) {						
			this.setProjectId(projectIndex);
			this.setPermission(permission);		
			this.setProjectView(true);
			this.$router.push("/reveloper-frontend/")
		},

		getProjectsAPI() {
			this.getProjectsAPICall().then(response => {
				this.getWorkspace()
			}, err => {
				this.isProjectLoading = false;
				this.isAssignProject = false;				
			})
		},
		getWorkspace() {
			this.getWorkspaceAPI().then(response => {				
			}, err => {
				console.log("Error" + err);
			})
		},

		async displayProjects() {
			if(this.isProjectCall || !this.isAssignProject){
				return false;
			}				
			const fetchPromises = this.projects.map(project => {			
				const projectIndex = this.workspace.folders.findIndex(item => item.id == project.project_id);			
				const projectIdToFetch = this.workspace.folders[projectIndex].folders[1].folders[0].sheets[1].id;				
				
				this.isProjectCall = true;

				const postUrl = `${this.smartsheetUrl}project-list`;
				const requestOptions = {
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({ projectIndex: projectIndex, sheetId: projectIdToFetch, permission: project.permissions}),
				};

				return fetch(postUrl, requestOptions)
				.then(response => response.json())
				.then(result => {			
					return result.data;
				})
				.catch(error => {
					console.error('Error:', error);
					return null;
				});
			});

			Promise.all(fetchPromises)
			.then(results => {
				this.isProjectLoading = false;
				this.projectList = results.filter(result => result !== null); 
			})
			.catch(error => {
				console.error('Error during parallel fetch requests:', error);
			});
		},
		goToDashboard() {
			this.$router.push("/dashboard");
		}
	},
}
</script>
<style scoped lang="scss">
.project-img-wrapper{
	background-position: center;
}
.project-access{
	font-weight: 600;
    font-size: 14px;
}

@media only screen and (min-width: 300px)and (max-width: 576px){
	.my-project-grid{
		margin: 0px 15px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		grid-row-gap: 0px;
	}
	.project-detail-wrapper {
    	padding: 1.125em 1.5em 0px;
	}
}
</style>