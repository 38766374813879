<template>
	<div class="container1 financing-page-wrap">
		<div class="row mt-4 mb-4">

			<div class="d-block col-12 col-lg-12 mt-2 mt-md-0">
				<div class="dashboard-content__section-border">
					<p class="dashboard-content__sub-section">Loan & Mortgage Info</p>
					<div class="dashboard-content__sub-section-content" v-if="!isLoadingInfo">
						<div class="to-do-list to-do-list-wrap width-auto ml-3 mr-3 mb-3">
							<table class="profile-details sales-profile-details sales-profile-details-top mob-table">
							<thead>
								<tr>
									<th style="width: 10px">-</th>
								<th>Loan amount
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">The amount of money borrowed on your project.</span>
									</a>
								</th>
								<th>Interest Rate
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">The interest rate received from the lender on the loan.</span>
									</a>
								</th>
								<th>Annual payments
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">The annual payments for the loan amount.</span>
									</a>
								</th>
								<th>Baseline: LTC
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">The project loan to cost percentage based on the original baseline budgets and projected costs of the project.</span>
									</a>
								</th>
								<th>Current: LTC
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">An accepted offer with all subjects removed.</span>
									</a>
								</th>
								<th>Addition DSCR
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">The project debt to service coverage ratio based on the new development addition.</span>
									</a>
								</th>
								<th>Total DSCR
									<a class="tooltipss">
									<img src="../assets/images/vector.png"/>
									<span class="tooltiptext">The total project debt to service coverage ratio.</span>
									</a>
								</th>

								</tr>
							</thead>
							<tbody>
								<tr>
								<td style="width: 10px">Total</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[1].displayValue}}</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[2].displayValue ?? '-'}}</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[3].displayValue}}</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[4].displayValue}}</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[5].displayValue}}</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[6].displayValue}}</td>
								<td style="font-weight: 700 !important;">{{loanInfo.rows[0].cells[7].displayValue}}</td>
								
								</tr>
							</tbody>
							</table>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="row mt-4 mb-4">			
			<div class="col-12 col-lg-4 mb-3 mt-2 mt-md-0 financing-loan2">
				<div class="dashboard-content__section-border">
					<p class="dashboard-content__sub-section margg-bott">Appraiser</p>
					<div class="dashboard-content__section-border ml-2 mr-2" style="box-shadow: none;">						
						<div class="dashboard-content__sub-section-content">
							<div class="d-flex justify-content-center">
								<div class="profil-pic-parent">
									<div class="profile-img-wrapper image-resize">
										<img v-if="!isLodingDirectory"
											:src="directory.images[0]"
											loading="lazy"
											alt=""
											class="profil-pic"
										/>
									</div>									
								</div>
							</div>
							<table class="profile-details sales-profile-details">							
								<tr>
									<td>Company</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[1].cells[4].displayValue}}</td>
								</tr>
								<tr>
									<td>Contact</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[2].cells[4].displayValue}}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[3].cells[4].displayValue}}</td>
								</tr>
								<tr>
									<td>Phone</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[4].cells[4].displayValue}}</td>
								</tr>
								<tr>
									<td>Street Address</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[6].cells[4].displayValue}}</td>
								</tr>
								<tr>
									<td>City, Province</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[7].cells[4].displayValue}}</td>
								</tr>
								<tr>
									<td>Postal Code</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[8].cells[4].displayValue}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4 mb-3 mt-2 mt-md-0 financing-loan2">
				<div class="dashboard-content__section-border">
					<p class="dashboard-content__sub-section margg-bott">Broker</p>
					<div class="dashboard-content__section-border ml-2 mr-2" style="box-shadow: none;">						
						<div class="dashboard-content__sub-section-content">
							<div class="d-flex justify-content-center">
								<div class="profil-pic-parent">
									<div class="profile-img-wrapper image-resize">
										<img v-if="!isLodingDirectory"
											:src="directory.images[1]"
											loading="lazy"
											alt=""
											class="profil-pic"
										/>
									</div>									
								</div>
							</div>
							<table class="profile-details sales-profile-details">							
								<tr>
									<td>Company</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[1].cells[5].displayValue}}</td>
								</tr>
								<tr>
									<td>Contact</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[2].cells[5].displayValue}}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[3].cells[5].displayValue}}</td>
								</tr>
								<tr>
									<td>Phone</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[4].cells[5].displayValue}}</td>
								</tr>
								<tr>
									<td>Street Address</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[6].cells[5].displayValue}}</td>
								</tr>
								<tr>
									<td>City, Province</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[7].cells[5].displayValue}}</td>
								</tr>
								<tr>
									<td>Postal Code</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[8].cells[5].displayValue}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-12 col-lg-4 mb-3 mt-2 mt-md-0 financing-loan2">
				<div class="dashboard-content__section-border">
					<p class="dashboard-content__sub-section margg-bott">Lender</p>
					<div class="dashboard-content__section-border ml-2 mr-2" style="box-shadow: none;">						
						<div class="dashboard-content__sub-section-content">
							<div class="d-flex justify-content-center">
								<div class="profil-pic-parent">
									<div class="profile-img-wrapper image-resize">
										<img v-if="!isLodingDirectory"
											:src="directory.images[2]"
											loading="lazy"
											alt=""
											class="profil-pic"
										/>
									</div>									
								</div>
							</div>
							<table class="profile-details sales-profile-details">							
								<tr>
									<td>Company</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[1].cells[6].displayValue}}</td>
								</tr>
								<tr>
									<td>Contact</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[2].cells[6].displayValue}}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[3].cells[6].displayValue}}</td>
								</tr>
								<tr>
									<td>Phone</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[4].cells[6].displayValue}}</td>
								</tr>
								<tr>
									<td>Street Address</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[6].cells[6].displayValue}}</td>
								</tr>
								<tr>
									<td>City, Province</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[7].cells[6].displayValue}}</td>
								</tr>
								<tr>
									<td>Postal Code</td>
									<td v-if="!isLodingDirectory">{{directory.info.rows[8].cells[6].displayValue}}</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script lang="js">
import { mapState } from 'vuex'
import { connectUrl } from '@/helpers/constants'
export default {
  	name: 'PostConstruction',
  	data() {
      return {       
        loanInfo:[],
		directory:[],
		isLoadingInfo:true,
        isLodingDirectory:true,
        smartsheetUrl:connectUrl          
      }
    },
	computed: {		
		...mapState(['workspace', 'projectId'])
	},
    methods: {
      getSmartsheet() {  
        fetch(`${this.smartsheetUrl}get-report?id=${this.workspace.folders[this.projectId].folders[1].folders[2].folders[2].reports[0].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.loanInfo = result.data 
			this.isLoadingInfo = false           
          }
        });

        fetch(`${this.smartsheetUrl}project-directory?id=${this.workspace.folders[this.projectId].folders[1].folders[0].sheets[1].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){		
            this.directory = result.data
			this.isLodingDirectory = false 
          }
        });
      }      
    },
    created() {
      this.getSmartsheet();
    }
}
</script>
<style lang="scss">
@import "@/assets/styles/_mixin.scss";

.mobile-view {
	display: none;
}

.desktop-view {
	display: block;
}

.home-first-section {
	position: relative;
}

.doc-section {
	@include mq("tablet", max) {
		position: absolute !important;
		top: 200%;
		left: 0px;
	}
}

.documentation-list {
	border: 1px solid #e9e8ed;
	border-radius: 16px;
	padding: 16px;
	margin: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__doc-list {
		font-family: "DM Sans";
		font-weight: 700;
		font-size: 14px;
		color: #131834;
	}

	&__file-count {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 14px;
		color: #a2aab3;
	}

	&__upload-type {
		@extend .documentation-list__file-count;
		font-weight: 500px;
	}
}

.construction-table {
	@include mq("tablet", max) {
		width: 100%;
		overflow: scroll;
	}

	.construction-update {
		width: 227px;
	}

	thead th {
		background: #f9f9fb;
		//background-color: lightgray;
	}

	.construction-table > thead th:first-child {
		border-radius: 8px 0 0 0px;
	}

	.construction-table > thead th:last-child {
		border-radius: 0 8px 0px 0;
	}
}

.construction-table > thead > tr > th {
	border: none !important;
}

.construction-table > tbody > tr > td {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 150%;
	text-align: left;
	color: #131834;
	border-top: 1px solid #e9e8ed;
}

.construction-table tbody > tr:first-child > td {
	border-top: 0 !important;
}

th {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: left !important;
	line-height: 18px;
	color: #a2aab3;
}

.financing-loan {
	border: 1px solid #E9E8ED;
	border-radius: 8px 8px 0px 0px !important;
}

span.tool-simbol {
	position: relative;
	left: -8px;
	/* right: 10px; */
	font-size: 9px;
	top: -3px;
	color: #a2aab3;
}

.col-12.col-lg-4.mb-4.mt-4.mt-md-0.financing-loan2 {
	float: left;
}

.financing-loan2 .dashboard-content__section-border.ml-2.mr-2 {
	border: none;
}

.financing-loan2 .profil-pic {
	width: 114px;
	height: 68px;
	object-fit: contain;
}

.profile-details tr > td:first-child {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #131834;
}

.profile-details {
	margin-top: 0px;
}

.financing-page-wrap .to-do-list .construction-table td:nth-child(2),
.financing-page-wrap .to-do-list .construction-table th:nth-child(2) {
	width: auto;
}

.financing-page-wrap .dashboard-content__sub-section-content {
	overflow: unset;
}

.financing-page-wrap .profile-details.sales-profile-details-top tr > td:first-child {
	width: 170px;
}

@media only screen and (max-width: 574px) {

	.dashboard-content .project-dashboard[data-v-ed558de2] {
		margin-left: 0px;
	}
	.container {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.dashboard-content__sub-section-content .to-do-list.to-do-list-wrap {
		width: 335px;
	}


	th {
		font-family: "DM Sans";
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		text-align: left !important;
		line-height: 20px;
		color: #1E1E1E;
		padding-left: 18px;
	}
}

.profil-pic-parent .image-resize {
	background-color: transparent !important;
	width: 114px !important;
}

.profil-pic-parent .image-resize img {
	width: 100%;
}

.color-lgg {
	font-weight: 500 !important;
	color: #1E1E1E;
}

.mob-table tr th {
	padding: .75rem;
	background: #F9F9FB;
}

.mob-table tr td {
	padding: .70rem;
}

.mob-table {
	border: 1px solid #E9E8ED;
	border-collapse: collapse;
}

@media screen and (max-width: 767px) {
  .mob-table {
    display: flex;
	
  }

  .mob-table thead th {
    display: flex;
	flex-direction: row;	 
	padding: 11px;
	margin-top: 10px;
	vertical-align: top;	
  }

  .mob-table tbody td {
    display: flex;
	flex-direction: row;
    border: none;   
	margin-top: 10px;	
	vertical-align: top;
  }
}


.width-auto {
	width: auto !important;
}

.mob-table tr > td:first-child {
	color: #1E1E1E !important;
}

@media only screen and (max-width: 767px) {
	.financing-loan2 .marg-top {
		margin-top: 10px !important;
	}
}

@media only screen and (min-width: 768px) {
	.margg-bott {
		margin-bottom: 30px !important;
	}
}

</style>