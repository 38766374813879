<template>
  <div id="app">
    <Layout/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Layout from '@/components/Layout.vue'

export default{

components: {
    Layout
},

computed:{
  ...mapGetters({})
},

data(){
  return{

  }
},

created() {
},

methods: { 
  ...mapActions({})
},

}


</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
