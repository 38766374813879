<template>
  <div class="tab-item-child">
      <div class="w-form auth-wrapper">
        <form class="user-profile-form">
          <div class="form-group">
            <label class="form-label" for="exampleInputPassword0">Old Password</label>
            <div class="input-group">
              <input :type="showOldPassword ? 'text' : 'password'" v-model="changePasswordDetails.oldPassword" class="rev-form-control" id="exampleInputPassword0" placeholder="Enter new passoword">
              <div class="pass-visibility" @click="toggleShowOldPassword">
                <div v-if="showOldPassword">
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.48885 15.806 4.75085 11.998 4.75085H12.002C8.194 4.75085 4.711 7.48885 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div v-else>
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.76045 14.3668C9.18545 13.7928 8.83545 13.0128 8.83545 12.1378C8.83545 10.3848 10.2474 8.9718 11.9994 8.9718C12.8664 8.9718 13.6644 9.3228 14.2294 9.8968" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M15.1049 12.699C14.8729 13.989 13.8569 15.007 12.5679 15.241" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M6.65451 17.4724C5.06751 16.2264 3.72351 14.4064 2.74951 12.1374C3.73351 9.85835 5.08651 8.02835 6.68351 6.77235C8.27051 5.51635 10.1015 4.83435 11.9995 4.83435C13.9085 4.83435 15.7385 5.52635 17.3355 6.79135" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.4473 8.99084C20.1353 9.90484 20.7403 10.9598 21.2493 12.1368C19.2823 16.6938 15.8063 19.4388 11.9993 19.4388C11.1363 19.4388 10.2853 19.2988 9.46729 19.0258" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.8868 4.24963L4.11279 20.0236" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="exampleInputPassword1">New Password</label>
            <div class="input-group">
              <input :type="showNewPassword ? 'text' : 'password'" v-model="changePasswordDetails.newPassword" class="rev-form-control" id="exampleInputPassword1" placeholder="Enter new passoword">
              <div  class="pass-visibility"  @click="toggleShowNewPassword">
                <div v-if="showNewPassword">
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.48885 15.806 4.75085 11.998 4.75085H12.002C8.194 4.75085 4.711 7.48885 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div v-else>
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.76045 14.3668C9.18545 13.7928 8.83545 13.0128 8.83545 12.1378C8.83545 10.3848 10.2474 8.9718 11.9994 8.9718C12.8664 8.9718 13.6644 9.3228 14.2294 9.8968" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M15.1049 12.699C14.8729 13.989 13.8569 15.007 12.5679 15.241" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M6.65451 17.4724C5.06751 16.2264 3.72351 14.4064 2.74951 12.1374C3.73351 9.85835 5.08651 8.02835 6.68351 6.77235C8.27051 5.51635 10.1015 4.83435 11.9995 4.83435C13.9085 4.83435 15.7385 5.52635 17.3355 6.79135" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.4473 8.99084C20.1353 9.90484 20.7403 10.9598 21.2493 12.1368C19.2823 16.6938 15.8063 19.4388 11.9993 19.4388C11.1363 19.4388 10.2853 19.2988 9.46729 19.0258" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.8868 4.24963L4.11279 20.0236" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" for="exampleInputPassword2">Re-enter New Password</label>
            <div class="input-group">
              <input :type="showConfirmPassword ? 'text' : 'password'" v-model="changePasswordDetails.confirmPassword" class="rev-form-control" id="exampleInputPassword2" placeholder="Re-enter new password">
              <div class="pass-visibility" @click="toggleShowConfirmPassword">
                <div v-if="showConfirmPassword">
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.998 19.3549C15.806 19.3549 19.289 16.6169 21.25 12.0529C19.289 7.48885 15.806 4.75085 11.998 4.75085H12.002C8.194 4.75085 4.711 7.48885 2.75 12.0529C4.711 16.6169 8.194 19.3549 12.002 19.3549H11.998Z" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
                <div v-else>
                  <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.76045 14.3668C9.18545 13.7928 8.83545 13.0128 8.83545 12.1378C8.83545 10.3848 10.2474 8.9718 11.9994 8.9718C12.8664 8.9718 13.6644 9.3228 14.2294 9.8968" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M15.1049 12.699C14.8729 13.989 13.8569 15.007 12.5679 15.241" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M6.65451 17.4724C5.06751 16.2264 3.72351 14.4064 2.74951 12.1374C3.73351 9.85835 5.08651 8.02835 6.68351 6.77235C8.27051 5.51635 10.1015 4.83435 11.9995 4.83435C13.9085 4.83435 15.7385 5.52635 17.3355 6.79135" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.4473 8.99084C20.1353 9.90484 20.7403 10.9598 21.2493 12.1368C19.2823 16.6938 15.8063 19.4388 11.9993 19.4388C11.1363 19.4388 10.2853 19.2988 9.46729 19.0258" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M19.8868 4.24963L4.11279 20.0236" stroke="#A2AAB3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>          
          <button type="button" class="btn btn-blue wd-100 mt-12 login-btn" @click="changePassword" :disabled="isButtonDisabled">
            <div v-if="isLoading" class="spinner"></div>
  					<div v-else class="txt-white">Change Password</div>
				</button>
        </form>
      </div>  
  </div>
</template>

<script lang="js">
import {mapGetters, mapActions, mapMutations} from 'vuex'
import * as types from '@/store/types'
import { useToast } from "vue-toastification";
export default {
    name: 'settings',   
    data(){
        return{
          changePasswordDetails:{
              id:"",
              oldPassword:"",
              newPassword:"",
              confirmPassword:""
            },
            showOldPassword: false,
            showNewPassword: false,
            showConfirmPassword: false,
            isButtonDisabled: false,
      		  isLoading: false 
        }
    },
    setup() {
      // Get toast interface
      const toast = useToast();

      // Make it available inside methods
      return { toast }
    },    
    methods: { 
      ...mapActions({changePasswordAPICall:types.ChangePassword_Action}),

      toggleShowOldPassword() {
        this.showOldPassword = !this.showOldPassword;
      },
      toggleShowNewPassword() {
        this.showNewPassword = !this.showNewPassword;
      },
      toggleShowConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
      },

      changePassword(){
        if(this.changePasswordDetails.oldPassword == "" || this.changePasswordDetails.newPassword == "" || this.changePasswordDetails.confirmPassword == ""){
            this.toast.info("Please Enter the Fields !");
        } else if(this.changePasswordDetails.newPassword != this.changePasswordDetails.confirmPassword){
            this.toast.info("Password didn't match !");
        } else{  
             // Disable the button and set loading to true
				    this.isButtonDisabled = true;
        		this.isLoading = true;	        
            this.changePasswordAPICall(this.changePasswordDetails).then(response =>{
              if(response.message == 'success'){
                this.changePasswordDetails.oldPassword = "";
                this.changePasswordDetails.newPassword = "";
                this.changePasswordDetails.confirmPassword = "";
                this.toast.success(response.notice); 
              }else{
                this.toast.error(response.notice); 
              }
              this.isButtonDisabled = false;
          		this.isLoading = false;         
            },error =>{
              this.isButtonDisabled = false;
          		this.isLoading = false; 
              this.toast.error(error.message);
            })
        }
      }
          
    },
}
</script>