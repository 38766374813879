// Make Constants for Actions, Mutations & Getters

export const LoginCustomer_Action = 'LoginCustomer_Action'

export const LogoutCustomer_Action = 'LogoutCustomer_Action'

export const SetAuth_Mutation = 'SetAuth_Mutation'

export const SetAuth_Getter = 'SetAuth_Getter'

export const GetProjects_Action = 'GetProjects_Action'

export const GetProjects_Mutation = 'GetProjects_Mutation'

export const UpdateProfilePic_Action = "UpdateProfilePic_Action"

export const GetProfilePic_Action = "GetProfilePic_Action"

export const UpdateCustomer_Action = 'UpdateCustomer_Action'

export const GetCustomer_Action = 'GetCustomer_Action'

export const SetUser_Mutation = 'SetUser_Mutation'

export const GetUser_Getter = 'GetUser_Getter'

export const ChangePassword_Action = 'ChangePassword_Action'

export const ResetPassword_Action = 'ResetPassword_Action'

export const ForgetPassword_Action = 'ForgetPassword_Action'

export const GetWorkspace_Action = 'GetWorkspace_Action'

export const GetWorkspace_Mutation = 'GetWorkspace_Mutation'

export const SetProjectId_Mutation = 'SetProjectId_Mutation'

export const SetPermission_Mutation = 'SetPermission_Mutation'

export const SetProjectView_Mutation = 'SetProjectView_Mutation'

export const SignUpCustomer_Action = 'SignUpCustomer_Action'




















