<template>
    <div class="mt-4">
      <div class="tabs-scroll">
        <ul class="breakdown-tab nav nav-tabs" id="costAndBudgetTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="construction-tab"
              data-toggle="tab"
              href="#construction"
              role="tab"
              aria-controls="construction"
              aria-selected="true"
              >Construction Costs</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="finance-tab"
              data-toggle="tab"
              href="#finance"
              role="tab"
              aria-controls="finance"
              aria-selected="false"
              >Finance Costs
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="lease-tab"
              data-toggle="tab"
              href="#lease"
              role="tab"
              aria-controls="lease"
              aria-selected="false"
              >Sales/Leasing Costs</a
            >
          </li>
        </ul>
      </div>
      <div class="tab-content" id="costAndBudgetTabContent">
        <div
          class="tab-pane fade show active"
          id="construction"
          role="tabpanel"
          aria-labelledby="construction-tab"
        >
          <div class="row mt-4">
            <div class="col-12 col-lg-6">
              <div class="dashboard-content__section-border mb-3 mb-md-0 pb-4">
                <p class="dashboard-content__sub-section soft-tab1">Soft Costs</p>
                <div class="dashboard-content__sub-section-content bor-bot bor-bott">
                  <div class="row ml-1 mr-1">                    
                    <div class="col-12 dashboard-graph">                     
                      <!-- <canvas ref="chartCanvas"></canvas> -->
                      <BarChart :jsonData="chart.softBar" v-if="!isLoading.soft" />
                    </div>
                  </div>
                </div>
                <div class="row mt-4 change-1">
                  <div class="col-12 col-lg-6 col-md-6 pt-4 ppp" id="ptb">
                    <div class="ml-5 mr-5 break-budget">
                      <p class="pad-0"><span class="mb-2 d-block font14">Current budget</span>                   
                      <span v-if="!isLoading.soft" class="cost-css">{{display.soft.currentBudget}}</span>
                    </p>
                    </div>
                    <div class="doughnutChart-wrap ml-4 mr-4 mmm">
                      <pie-chart :jsonData="chart.softPie" v-if="!isLoading.soft"/>
                    </div>                   
                  </div>
                  <div class="col-12 col-lg-6 col-md-6 cost-details cost-details-break pt-4">
                    <div class="ml-2 mr-2 cost-border mar-lr">
                      <div>
                        <p class="actual-cost mb-1">
                          <span></span>To-date costs
                        </p>
                        <p  v-if="!isLoading.soft" class="cost-value">{{display.soft.toDateCost}}</p>
                      </div>
                      <div>
                        <p class="total-cost mb-1">
                          <span></span>Total Costs Balance
                        </p>
                        <p  v-if="!isLoading.soft" class="cost-value">{{display.soft.totalCost}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6">
              <div class="dashboard-content__section-border mb-3 mb-md-0 pb-4">
                <p class="dashboard-content__sub-section soft-tab1">Hard Costs</p>
                <div class="dashboard-content__sub-section-content bor-bot bor-bott">
                  <div class="row ml-1 mr-1">                   
                    <div
                      class="col-12 dashboard-graph">
                      <BarChart :jsonData="chart.hardBar" v-if="!isLoading.hard" />
                    </div>
                  </div>
                </div>
                <div class="row mt-4 change-1">
                  <div class="col-12 col-lg-6 col-md-6 pt-4 ppp" id="ptb">
                    <div class="ml-5 mr-5 break-budget">
                      <p class="pad-0"><span class="mb-2 d-block font14">Current budget</span>                   
                      <span v-if="!isLoading.hard" class="cost-css">{{display.hard.currentBudget}}</span>
                    </p>
                    </div>
                    <div class="doughnutChart-wrap ml-4 mr-4 mmm">
                      <pie-chart :jsonData="chart.hardPie" v-if="!isLoading.hard"/>
                    </div>                    
                  </div>
                  <div class="col-12 col-lg-6 col-md-6 cost-details cost-details-break pt-4">
                    <div class="ml-2 mr-2 cost-border mar-lr">
                      <div>
                        <p class="actual-cost mb-1">
                          <span></span>To-date costs
                        </p>
                        <p  v-if="!isLoading.hard" class="cost-value">{{display.hard.toDateCost}}</p>
                      </div>
                      <div>
                        <p class="total-cost mb-1">
                          <span></span>Total Costs Balance
                        </p>
                        <p  v-if="!isLoading.hard" class="cost-value">{{display.hard.totalCost}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3" id="mtb">
            <div class="col-12">
              <div class="dashboard-content__section-border mb-3 mb-md-0">
                <p class="dashboard-content__sub-section mb-3">
                  Cost Categories
                </p>
                <div class="dashboard-content__sub-section-content">
                  <div class="cost-breakdown">
                    <div v-html="cost.soft" class="embeded"></div>
                    <div v-html="cost.hard" class="embeded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="finance"
          role="tabpanel"
          aria-labelledby="finance-tab"
        >
          <div class="row mt-3 mb-3" id="mbt">
            <div class="col-12">
              <div class="dashboard-content__section-border mb-3 mb-md-0">
                <p class="dashboard-content__sub-section soft-tab1">Finance Costs</p>
                <div class="dashboard-content__sub-section-content">
                  <div class="row ml-1 mr-1 pb-4"  id="mlr">
                    <div class="col-12 col-lg-6 dashboard-graph">                      
                      <BarChart :jsonData="chart.financialBar" v-if="!isLoading.financial" />
                    </div>
                    <div class="col-12 col-lg-6 border-left right-side-chart">
                      <div class="row mt-4">
                        <div class="col-12 col-lg-6 col-md-6">
                          <div class="ml-5 mr-5 break-budget">
                           <p class="pad-0"><span class="mb-2 d-block font14">Current budget</span>
                           <span v-if="!isLoading.financial" class="cost-css">{{display.financial.currentBudget}}</span></p>
                          </div>
                          <div class="doughnutChart-wrap2 ml-4 mr-4 mmm">
                            <pie-chart :jsonData="chart.financialPie" v-if="!isLoading.financial"/>
                          </div>                          
                        </div>
                        <div class="col-12 col-lg-6 col-md-6 cost-details cost-details-break">
                          <div class="ml-2 mr-2 cost-border mar-lr" id="no-bor">
                            <div>
                              <p class="actual-cost mb-1">
                                <span></span>To-date costs
                              </p>
                              <p v-if="!isLoading.financial" class="cost-value">{{display.financial.toDateCost}}</p>
                            </div>
                            <div>
                              <p class="total-cost mb-1">
                                <span></span>Total Costs Balance
                              </p>
                              <p v-if="!isLoading.financial" class="cost-value">{{display.financial.totalCost}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3" id="mtb">
            <div class="col-12">
              <div class="dashboard-content__section-border mb-3 mb-md-0">
                <p class="dashboard-content__sub-section mb-3">
                  Cost Categories
                </p>
                <div class="dashboard-content__sub-section-content">
                  <div class="cost-breakdown">
                    <div v-html="cost.financial" class="embeded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="lease"
          role="tabpanel"
          aria-labelledby="lease-tab"
        >
          <div class="row mt-3 mb-3" id="mbt">
            <div class="col-12">
              <div class="dashboard-content__section-border mb-3 mb-md-0">
                <p class="dashboard-content__sub-section soft-tab1">Sales/Leasing Costs</p>
                <div class="dashboard-content__sub-section-content">
                  <div class="row ml-1 mr-1 pb-3" id="mlr">
                    <div class="col-12 col-lg-6 dashboard-graph">                      
                      <BarChart :jsonData="chart.leaseBar" v-if="!isLoading.lease" />
                    </div>
                    <div class="col-12 col-lg-6 border-left right-side-chart">
                      <div class="row mt-4">
                        <div class="col-12 col-lg-6 col-md-6">
                          <div class="ml-5 mr-5 break-budget">
                           <p class="pad-0"><span class="mb-2 d-block font14">Current budget</span>
                           <span v-if="!isLoading.lease" class="cost-css">{{display.lease.currentBudget}}</span></p>
                         </div>
                          <div class="doughnutChart-wrap2 ml-4 mr-4 mmm">
                            <pie-chart :jsonData="chart.leasePie" v-if="!isLoading.lease"/>
                          </div>                          
                        </div>
                        <div class="col-12 col-lg-6 col-md-6 cost-details cost-details-break">
                          <div class="ml-2 mr-2 cost-border mar-lr mm">
                            <div>
                              <p class="actual-cost mb-1">
                                <span></span>To-date costs
                              </p>
                              <p v-if="!isLoading.lease" class="cost-value">{{display.lease.toDateCost}}</p>
                            </div>
                            <div>
                              <p class="total-cost mb-1">
                                <span></span>Total Costs Balance
                              </p>
                              <p v-if="!isLoading.lease" class="cost-value">{{display.lease.totalCost}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3 mb-3" id="mtb">
            <div class="col-12">
              <div class="dashboard-content__section-border mb-3 mb-md-0">
                <p class="dashboard-content__sub-section mb-3">
                  Cost Categories
                </p>
                <div class="dashboard-content__sub-section-content">
                  <div class="cost-breakdown">
                    <div v-html="cost.lease" class="embeded"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <script lang="js">
  import { connectUrl } from '@/helpers/constants'
  import BarChart from '@/components/BarChart.vue';
  import PieChart from '@/components/PieChart.vue';
  import { mapState } from 'vuex'
  export default {
    name: 'FinancialCostBreakdown',
    components: {
      BarChart,
      PieChart
    },
    data() {
        return { 
          chart:{
            softBar:null,
            softPie:null,
            hardBar:null,
            hardPie:null,
            financialPie:null,
            financialBar:null,
            leaseBar:null,
            leasePie:null
          },         
          isLoading:{
            soft:true,
            hard:true,
            financial:true,
            lease:true
          },
          cost:{
            soft:'',
            hard:'',
            financial:'',
            lease:''
          }, 
          display:{
            hard:{
              currentBudget:'',
              toDateCost:'',
              totalCost:'',
            },
            soft:{
              currentBudget:'',
              toDateCost:'',
              totalCost:'',
            },
            financial:{
              currentBudget:'',
              toDateCost:'',
              totalCost:'',
            },
            lease:{
              currentBudget:'',
              toDateCost:'',
              totalCost:'',
            }
          },          
          smartsheetUrl:connectUrl          
        }
    },
    created() {
      this.getSmartsheet();
    },
    computed: {		
      ...mapState(['workspace', 'projectId'])
    },
    methods: {
        getSmartsheet() {  
          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[0].sights[1].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){ 
              this.chart.softPie =  result.data.widgets[1] 
              this.chart.softBar =  result.data.widgets[2]                    
              this.display.soft.currentBudget = result.data.widgets[3].contents.cellData[0].cell.displayValue      
              this.display.soft.toDateCost = result.data.widgets[0].contents.cellData[1].cell.displayValue 
              this.display.soft.totalCost = result.data.widgets[0].contents.cellData[0].cell.displayValue 
              this.isLoading.soft = false
            }
          });       
  
          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[0].sights[2].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){
              this.chart.hardPie =  result.data.widgets[1] 
              this.chart.hardBar =  result.data.widgets[2]                    
              this.display.hard.currentBudget = result.data.widgets[3].contents.cellData[0].cell.displayValue      
              this.display.hard.toDateCost = result.data.widgets[0].contents.cellData[1].cell.displayValue 
              this.display.hard.totalCost = result.data.widgets[0].contents.cellData[0].cell.displayValue 
              this.isLoading.hard = false			             
            }
          });

          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[0].sights[3].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){  
              this.cost.soft = result.data.widgets[1].contents.htmlContent           
              this.cost.hard = result.data.widgets[0].contents.htmlContent             
            }
          });

          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[1].sights[1].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){
              this.chart.financialPie = result.data.widgets[1] 
              this.chart.financialBar = result.data.widgets[2]                    
              this.display.financial.currentBudget = result.data.widgets[3].contents.cellData[0].cell.displayValue      
              this.display.financial.toDateCost = result.data.widgets[0].contents.cellData[1].cell.displayValue 
              this.display.financial.totalCost = result.data.widgets[0].contents.cellData[0].cell.displayValue 
              this.isLoading.financial = false			             
            }
          });

          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[1].sights[2].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){  
              this.cost.financial = result.data.widgets[0].contents.htmlContent  
            }
          });  

          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[2].sights[1].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){
              this.chart.leasePie = result.data.widgets[1] 
              this.chart.leaseBar = result.data.widgets[2]                    
              this.display.lease.currentBudget = result.data.widgets[0].contents.cellData[0].cell.displayValue      
              this.display.lease.toDateCost = result.data.widgets[3].contents.cellData[1].cell.displayValue 
              this.display.lease.totalCost = result.data.widgets[3].contents.cellData[0].cell.displayValue 
              this.isLoading.lease = false			             
            }
          });

          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[2].folders[1].folders[2].sights[2].id}`) 
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){  
              this.cost.lease = result.data.widgets[0].contents.htmlContent  
            }
          }); 
          
      }  
    }  
  }
  </script>
  <style lang="scss">
  @import "@/assets/styles/_mixin.scss";  
  .tabs-scroll {
    overflow: auto;
  }

  .dashboard-content{
    .tabs-scroll{
      .breakdown-tab{
            flex-wrap: nowrap;
        .nav-item{
              margin-left: 0px;
         .nav-link.active, .nav-link.active.show {border-radius: 1em 1em 0em 0em !important;}
        }
      }
    }
  }
  .right-side-chart{display: flex;align-items: center; justify-content:center;}
  .right-side-chart > .row{width:100%;}
  .doughnutChart-wrap, .doughnutChart-wrap2{margin-top:-14%;}
  /*.right-side-chart .actual-cost-percentage{margin-top:-20px;}*/
  .font14{font-size:14px;}
  
  
  .tabs-scroll::-webkit-scrollbar {
    width: .25em;
    height: .25em;
  }
   
  .tabs-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
  }
   
  .tabs-scroll::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 0px solid slategrey;
  }

  .doughnutpercentage.tab1 {
    top: 74%;
  }
  .cost-details {
    @include mq("tablet", max) {
      border-left: 0;
    }
  
    > div {
      @include mq("tablet", max) {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        border-bottom: 1px solid #e9e8ed !important;
      }
    }
  }
  .cost-breakdown {
    @include mq("tablet", max) {
      width: 1045px;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  .cost-and-budget-table-breakdown {
    thead th {
      background: #a2aab3;
    }
    th {
      font-family: "DM Sans";
  
      font-weight: 500;
      font-size: 14px;
      color: white;
      padding: 14px !important;
      text-align: left;
      border: 0 !important;
    }
    td {
      text-align: left;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 14px;
      // padding: 8px !important;
      color: #131834;
    }
    thead th:first-child {
      border-radius: 8px 0 0 0px;
    }
    thead th:last-child {
      border-radius: 0 8px 0 0px;
    }
    tbody {
      border: 1px solid #e9e8ed;
      border-top: 0 !important;
    }
    tbody tr:first-child td,
    tr:nth-child(7) > td {
      background: #174486;
      color: white;
      border-top: 0 !important;
    }
    tbody tr td:nth-child(2) {
      font-weight: 500;
    }
    tbody tr td:last-child {
      font-weight: 700;
    }
  }
  .dashboard-graph{
    position: relative; height: auto;
    
  }
  .color-red{
    color: #FF4D4F !important;
  }

  @media only screen and (max-width:992px){
    .border-left{
      border-left: 1px solid #E9E8ED;
    }
  }
  
  @media only screen and (max-width:767px){  
    .dd{
      padding-bottom: 220px !important;
    }
    .ddd{
      padding-bottom: 228px !important;
    }
    
    .mm{
      margin-top: -13%;
    }
    .ppp{
     padding-left: 41px !important;
     padding-right: 41px !important;
    }
    .mmm{
      margin-top: -23% !important;
    }
    #no-bor{
      border-bottom: none !important;
      margin-top: -13%;
    }
    #mlr{
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-bottom: 0px !important;
    }
    .bor-bott{
      padding-bottom: 30px !important;
    }
    #ptb{
      padding-top: 0px !important;
    }
    #mbt{
      margin-bottom: 0px !important;
    }
    #mtb{
      margin-top: 0px !important;
    }
    .cost-details .mar-lr{
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
   .right-side-chart .cost-details .mar-lr{
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .cost-details.cost-details-break.pt-4{padding-top:0rem!important; margin-top:-12%;}
  .bor-bot{
    border-bottom: 1px solid #E9E8ED;
    padding-bottom: 50px;
  }
  }

  
 
  @media only screen and (min-width:768px){
    .row.mt-4.change-1.soft-change {
      margin-top: 67px !important;
  }
    .change-1{
      margin-left: 20px !important;
      margin-right: 20px !important;
      border-top: 1px solid #E9E8ED;
    }
    .pad-0{
      margin-bottom: 0px !important;
    }
    
    .cost-border{
    padding: 25px;
    border: 1px solid #E9E8ED;
    border-radius: 16px;
  }
  }
  .cost-value {
      margin-left: 14px;
  }
  .radion-right .total-cost > span {
      background: #1890FF;
  }
  .radion-right {
      margin-left: 20px;
  }
  .cost-and-budget-table-breakdown tbody tr td:nth-child(5) {
    font-weight: 700;
  }
  .cost-and-budget-table-breakdown tbody tr td:nth-child(6) {
      font-weight: 700;
  }
  .blue-tab1 {
    background: rgba(24, 144, 255, 0.2);
  }
  .breakdown-radion.row.ml-1.mr-1 {
      margin-bottom: 20px;
  }
  .dashboard-content__sub-section.soft-tab1 {
      margin-bottom: 20px !important;
  }
  .dashboard-content__sub-section-content.bor-bot.break-first-graph {
      overflow: revert;
  }
  </style>
  