import * as types from './types'

export default{
    [types.GetWorkspace_Mutation](state, response) {
        state.workspace = response; 
    },
    [types.SetAuth_Mutation](state, response) {
        state.auth = response; 
    },
    [types.SetProjectId_Mutation](state, response) {       
        state.projectId = response; 
    },
    [types.SetPermission_Mutation](state, response) {       
        state.permission = response; 
    },   

    [types.SetProjectView_Mutation](state, response) {       
        state.projectView = response; 
    },

    [types.GetProjects_Mutation](state, response) {       
        state.projects = response; 
    },

    [types.SetUser_Mutation](state, response) {       
        state.user = response; 
    }  
    
    
}