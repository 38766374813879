import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import actions from './actions'
import mutations from './mutations'
import getters from './getters'


export default createStore({
  state: { 
    auth:'',
    workspace : {},
    projectId : '',
    permission:'',
    projects : {},
    projectView : false,
    user:{}
  },
  getters,
  mutations,
  actions,
  modules: {},
  plugins: [
    createPersistedState({
      key: 'project',
      storage: window.localStorage,
      paths: ['auth', 'projectId', 'workspace', 'projectView', 'permission', 'user'],
    }),
  ],
});
