<template>
  <div class=" post-constuction-section">
    <div class="tabs-scroll">
      <ul class="nav nav-tabs" id="costAndBudgetTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="general-tab"
            data-toggle="tab"
            href="#general"
            role="tab"
            aria-controls="general"
            aria-selected="true"
            >General</a
          >
        </li>
        <li class="nav-item last-item" v-if="this.permission != 'Lessee/Buyer'">
          <a
            class="nav-link"
            id="rent-tab"
            data-toggle="tab"
            href="#rent"
            role="tab"
            aria-controls="rent"
            aria-selected="false"
            >Tenant Chart
          </a>
        </li>
      </ul>
    </div>
    <div class="tab-content" id="costAndBudgetTabContent">
    <div
        class="tab-pane fade show active"
        id="general"
        role="tabpanel"
        aria-labelledby="general-tab"
      >
      <div class="row mt-3 mt-lg-4 property-management align-items-start">
        <div class="col-12 col-lg-4 mt-3 mt-lg-0">
          <div class="dashboard-content__section-border">
            <p class="dashboard-content__sub-section">Property Manager</p>
            <div class="dashboard-content__sub-section-content">
              <div class="d-flex justify-content-center">
                <div class="profil-pic-parent">
                  <div class="profile-img-wrapper">
                    <img v-if="!isLoding"
                      :src="jsonOutput.images[0]"
                      loading="lazy"
                      alt=""
                      class="profil-pic"
                    />
                  </div>                  
                </div>
              </div>
              <table class="profile-details construction-profile-details">
                <tr>
                  <td>Company</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[1].cells[11].displayValue}}</td>
                </tr>
                <tr>
                  <td>Contact</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[2].cells[11].displayValue}}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[3].cells[11].displayValue}}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[4].cells[11].displayValue}}</td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[6].cells[11].displayValue}}</td>
                </tr>
                <tr>
                  <td>City, Province</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[7].cells[11].displayValue}}</td>
                </tr>
                <tr>
                  <td>Postal Code</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[8].cells[11].displayValue}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-3 mt-lg-0">
          <div class="dashboard-content__section-border">
            <p class="dashboard-content__sub-section">Insurance</p>
            <div class="dashboard-content__sub-section-content">
              <div class="d-flex justify-content-center">
                <div class="profil-pic-parent">
                  <div class="profile-img-wrapper">
                    <img v-if="!isLoding"
                      :src="jsonOutput.images[1]"
                      loading="lazy"
                      alt=""
                      class="profil-pic"
                    />
                  </div>                  
                </div>
              </div>
              <table class="profile-details construction-profile-details">
                <tr>
                  <td>Company</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[1].cells[12].displayValue}}</td>
                </tr>
                <tr>
                  <td>Contact</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[2].cells[12].displayValue}}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[3].cells[12].displayValue}}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[4].cells[12].displayValue}}</td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[6].cells[12].displayValue}}</td>
                </tr>
                <tr>
                  <td>City, Province</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[7].cells[12].displayValue}}</td>
                </tr>
                <tr>
                  <td>Postal Code</td>
                  <td v-if="!isLoding">{{ jsonOutput.directory.rows[8].cells[12].displayValue}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      
        <div class="col-12 col-lg-4 mt-3 mt-md-0 mb-4">
          <div class="dashboard-content__section-border last_sub-section">
            <p class="dashboard-content__sub-section ">
              Documents
            </p>
            <div class="documentation-list mb-3">
				      <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[59].contents.shortcutData[0].hyperlink.url)">
              <div class="d-flex justify-content-start align-items-center">
                <div class="document-list-icon mr-2"></div>
                <div class="">
                  <p class="mb-0 documentation-list__doc-list">
                    Final design drawings
                    <a class="tooltipss"><img src="@/assets/images/vector.png" /><span class="tool-simbol">!</span>
                      <span class="tooltiptext insurance">All final design drawings & reports (as-builts) from consultants such as the architect and engineers.</span>
					        </a>
                  </p>                 
                </div>
              </div>
				    </button>
            </div>
            <div class="documentation-list mb-3">
			          <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[61].contents.shortcutData[0].hyperlink.url)">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="document-list-icon mr-2"></div>
                    <div class="">
                      <p class="mb-0 documentation-list__doc-list">
                        Insurance, warranties & manuals
                        <a class="tooltipss"><img src="@/assets/images/vector.png" /><span class="tool-simbol">!</span>
                            <span class="tooltiptext insurance">All documents related to insurance, warranties and operation manuals.</span>
                          </a>
                      </p>                  
                    </div>
                  </div>
			        </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        class="tab-pane fade"
        id="rent"
        role="tabpanel"
        aria-labelledby="rent-tab"
      >
      <div class="row mb-lg-3 align-items-start">
        <div class="col-12 my-4">
          <div class="dashboard-content__section-border">
            <!-- <p class="dashboard-content__sub-section">Tenant Chart</p> -->
            <div class="dashboard-content__sub-section-content">
              <div class="ml-2 mr-2 consultant-table-scroll">
                <div v-html="chart" class="embeded"></div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script lang="js">
import { connectUrl } from '@/helpers/constants'
import { mapState } from 'vuex'
export default {
  name: 'PostConstruction',
  data() {
      return {
        jsonOutput:[],
        dashboard:[],
        chart:'',
        isLoding:true,
        isLodingDoc:true,
        smartsheetUrl:connectUrl          
      }
    },
    computed: {		
      ...mapState(['workspace', 'projectId', 'permission'])
    },
    methods: {
      getSmartsheet() {  
        fetch(`${this.smartsheetUrl}post-construction-general?id=${this.workspace.folders[this.projectId].folders[1].folders[0].sheets[1].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.jsonOutput = result.data
            this.isLoding = false 
          }
        });

        fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[6].sights[0].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.chart = result.data.widgets[0].contents.htmlContent
          }
        }); 
        
        if(this.workspace.folders[this.projectId].folders[0].folders[0].sights && this.workspace.folders[this.projectId].folders[0].folders[0].sights[0]){
          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[0].folders[0].sights[0].id}`)
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){
              this.isLodingDoc = false
              this.dashboard = result.data           
            }
          });
        }
      },
      redirectToLink(link){
        window.open(link, '_blank')		
      }
    },
    created() {
      this.getSmartsheet();
    }
}
</script>
<style lang="scss">
@import "@/assets/styles/_mixin.scss";
.tenant-table {
  tbody tr:last-child > td {
    font-family: "DM Sans";
    font-weight: 500;
    font-size: 12px;
    color: #131834;
  }
  thead tr:nth-child(1) > td {
    background: #174486;
    border: 1px solid #e9e8ed;
    color: #ffffff;
    text-align: left;
  }
}
.property-management {
  @include mq("tablet", max) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.post-constuction-section {
  @include mq("tablet", max) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.yellow-bcg{
  background:rgba(254,254,133,0.2);
}
.post-constuction-section .table-bordered {
    margin-top: 15px;
}
.post-constuction-section .profile-img-wrapper{
    width: 100%!important;
    height: 85px !important;
}
//.post-constuction-section .nav-tabs .nav-item{
//    width: 240px;
//}
.consultant-table tr th{
    background-color:#A2AAB3;
    color: #fff;
}

.consultant-table .bttm-bar{
    background-color: #F2F2F2;
}
.consultant-table tbody tr > td{
    color: #131834;
}
//.nav-tabs li.last-item{
//    width: 30px!important;
//    background-color: #f9f9fb;
//    /* position: relative; */
//    z-index: 9;
//    border-bottom-left-radius: 1em;
//}
  /*.post-constuction-section .nav-link.active.show{
    border-radius: 24px 24px 0px 0px !important;
  }
  .post-constuction-section .nav-link.active.show{
    border-radius: 24px 24px 0px 0px !important;
  }*/
  //.post-constuction-section .nav-link.active{
  //  border-radius: 0.5em 0.5em 0em 0em !important;
  //}

.dashboard-content .tooltipss{ 
  font-weight:700;
}

.last_sub-section{
  height:103%;
}
.last_sub-section .dashboard-content__sub-section{
  background-color:#EBE8E1!important;
  margin-bottom: 18px!important;
}

/*responsive style*/
@media screen and (max-width: 767px) {
  .post-constuction-section {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .post-constuction-section .property-management{
    flex-direction: column;
    margin-top:0!important;
  }
  .post-constuction-section .tab-content{
    margin-top:0;
  }
  .post-constuction-section .property-management .mt-3{
    margin-top: 2rem!important;
  }
  #costAndBudgetTab{
    width:100%;
  }

}
.post-constuction-section span.tooltiptext.insurance {
    bottom: 24px;
    margin-left: -82px;
    width: 133px;
}

</style>