import * as types from './types'

export default{
   
    [types.SetAuth_Getter]: state=>{
        return state.auth; 
    },  
    
    [types.GetUser_Getter]: state=>{
        return state.user;
    },
}