import * as types from './types'
import {connectUrl} from '@/helpers/constants'
import HTTPSCalls from '../../src/lib/http-services'
import {useToast} from 'vue-toastification'
import axios from 'axios'
// import * as dotenv from 'dotenv'

// dotenv.config()
const toast = useToast();

export default {

    [types.LoginCustomer_Action]: function ({commit, state}, reqData) {        
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .post(connectUrl + "login", JSON.parse(reqData))
            .then(response => {               
                commit(types.SetAuth_Mutation, response.data.token);
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        })
    },

    [types.LogoutCustomer_Action]: function ({commit}) {        
        commit(types.SetAuth_Mutation, null);
    },
 
    [types.SignUpCustomer_Action]: function ({commit, state}, reqData) {        
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .post(connectUrl + "signup", JSON.parse(reqData))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        })
    },    

    [types.UpdateCustomer_Action]: function ({commit, state}, reqData) {             
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .post(connectUrl + "user", JSON.parse(reqData))
            .then(response => {
                commit(types.SetUser_Mutation, JSON.parse(reqData));
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        })
    },  
    
    [types.GetCustomer_Action]: function ({commit, state}, reqData) {        
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .get(connectUrl + "user")
            .then(response => {
                commit(types.SetUser_Mutation, response.data.data);
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        })
    },  
    

    [types.ForgetPassword_Action]: function ({commit, state}, reqData) {
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .post(connectUrl + "forgot-password", reqData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            });
        })
    },

    [types.ResetPassword_Action]: function ({commit, state}, reqData) {
        return new Promise((resolve, reject) => {
            HTTPSCalls
                .post(connectUrl + "reset-password", reqData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {                                   
                    reject(error);
                });
        })
    },

    [types.ChangePassword_Action]: function ({commit, state}, reqData) {
        return new Promise((resolve, reject) => {
            HTTPSCalls
                .post(connectUrl + "change-password", reqData)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {                                   
                    reject(error);
                });
        })
    },

    [types.GetProjects_Action]: function ({commit, state}) {
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .get(connectUrl + "projects")
            .then(response => {
                if (response.status === 200) {
                    commit(types.GetProjects_Mutation, response.data.data);
                    resolve(response.data);
                } else {             
                    reject(response);
                }
            })
            .catch(error => {                    
                reject(error);
            });
        })
    },

    [types.GetProfilePic_Action]: function({ commit, state }, reqData){
        return new Promise((resolve, reject) => {
          console.log("In Action");
          const id = localStorage.getItem('c_id');
          HTTPSCalls
            .get(connectUrl+"getProfilePic/"+id,{headers:{
              'x-access-token':`${localStorage.getItem('access_token')}`
            },responseType: 'blob'})
            .then(response => {
              // console.log("API Response:");
              resolve(response);

              // if(response.data.status == 200){
              //   commit(types.GetProfilePic_Mutation,response);
              //   toast.success(response.data.message);
              //   resolve(response.data);
              // }else{
              //   toast.info(response.data.message);
              //   console.log("API Call Ended in Reject");
              //   reject(response);
              // }

              })
              .catch(error => {
                reject(error);
                toast.info(error.message+"!");
                console.log("API Call Ended in Error");
              });
        })

      },

    [types.UpdateProfilePic_Action]: function({ commit, state }, reqData){
        return new Promise((resolve, reject) => {
        console.log("In Action Update Profile Pic"+ reqData);
        const id = localStorage.getItem('c_id');
          HTTPSCalls
            .post(connectUrl+"updateProfilePhoto/"+id,reqData,{
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            })
            .then(response => {
              if(response.data.status == 200){
                // commit(types.UpdateProfilePic_Mutation, reqData);
                toast.success(response.data.message);
                resolve(response.data);
              }else{
                toast.info(response.data.message);
                console.log("API Call Ended in Reject");
                reject(response);
              }
              })
              .catch(error => {
                reject(error);
                toast.info(error.message+"!");
                console.log("API Call Ended in Error");
              });
        })

    },

    [types.GetWorkspace_Action]: function ({ commit, state }) {
        return new Promise((resolve, reject) => {
            HTTPSCalls
            .get(connectUrl + "workspace")
            .then(response => {
                if (response.status === 200) {                    
                    commit(types.GetWorkspace_Mutation, response.data);
                    resolve(response.data);
                } else {             
                    reject(response);
                }
            })
            .catch(error => {                    
                reject(error);
            });
        })
    },
}