<template>
	<div class="row home-first-section">
		<div class="col-lg-8 col-md-12 col-sm-12 mt-4 mt-md-0" :class="{ 'col-lg-12': permission === 'Lessee/Buyer' || permission === 'General' }">
			<div class="dashboard-content__section-border">
				<p class="dashboard-content__sub-section">Latest News</p>
				<div class="dashboard-content__sub-section-content ml18 mr18">
					<div class="latest-news mr0 ml0">	
						<div v-html="news" class="embeded"></div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-4 d-none d-lg-block doc mt-4 mt-md-0" v-if="this.permission != 'Lessee/Buyer' && this.permission != 'General'">
			<div class="dashboard-content__section-border mb-3 mb-md-0" style="height:100%; position: relative">
				<p id="rightcol" class="dashboard-content__sub-section doc-part sand-background">Documents
					<a class="tooltipss"><img src="@/assets/images/vector.png"/>
						<span
							class="tooltiptext">An up-to-date cloud storage location for all the projects documents.</span>
					</a>
				</p>
				<div class="documentation-list">			
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[79].contents.shortcutData[0].hyperlink.url)" >
					<div class="d-flex justify-content-start align-items-center" >
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Meeting Minutes
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span
										class="tooltiptext">All meeting minutes that have been taken for the project.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
				<div class="documentation-list" v-if="this.permission == 'General' || this.permission == 'Lessee/Buyer' || this.permission === 'Owner'">
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[29].contents.shortcutData[0].hyperlink.url)">
					<div class="d-flex justify-content-start align-items-center">
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Contracts
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span class="tooltiptext">All contracts that are in place for the project.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
				<div class="documentation-list" v-if="this.permission === 'General' || this.permission === 'Lessee/Buyer' || this.permission === 'Owner'">
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[26].contents.shortcutData[0].hyperlink.url)">
					<div class="d-flex justify-content-start align-items-center">
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Invoices
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span class="tooltiptext">All invoices for the project, so you always know what is being paid for.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
				<div class="documentation-list">
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[25].contents.shortcutData[0].hyperlink.url)">
					<div class="d-flex justify-content-start align-items-center">
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Drawings & Reports
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span class="tooltiptext">All drawings & reports from consultants such as the architect and engineers.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row mt-4 mb-4 home-second" v-if="this.permission != 'General' && this.permission != 'Lessee/Buyer'">
		<div class="col-12">
			<div class="dashboard-content__section-border">
				<p class="dashboard-content__sub-section">To Do list</p>
				<div class="dashboard-content__sub-section-content ml18 mr18">
					<div class="to-do-list mr0 ml0">
						<div v-html="todo" class="embeded"></div>						
					</div>
				</div>
			</div>
		</div>
		<div class="col-12 d-block d-lg-none mt-4 mt-md-0 mb-4">
			<div class="dashboard-content__section-border">
				<p id="rightcol" class="dashboard-content__sub-section doc-part sand-background">Documents
					<a class="tooltipss"><img src="@/assets/images/vector.png"/>
						<span
							class="tooltiptext">An up-to-date cloud storage location for all the projects documents.</span>
					</a>
				</p>
				<div class="documentation-list">			
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[79].contents.shortcutData[0].hyperlink.url)" >
					<div class="d-flex justify-content-start align-items-center" >
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Meeting Minutes
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span
										class="tooltiptext">All meeting minutes that have been taken for the project.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
				<div class="documentation-list" v-if="this.permission === 'General' || this.permission === 'Lessee/Buyer' || this.permission === 'Owner'">
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[29].contents.shortcutData[0].hyperlink.url)">
					<div class="d-flex justify-content-start align-items-center">
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Contracts
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span class="tooltiptext">All contracts that are in place for the project.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
				<div class="documentation-list" v-if="this.permission === 'General' || this.permission === 'Lessee/Buyer' || this.permission === 'Owner'">
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[26].contents.shortcutData[0].hyperlink.url)">
					<div class="d-flex justify-content-start align-items-center">
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Invoices
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span class="tooltiptext">All invoices for the project, so you always know what is being paid for.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
				<div class="documentation-list">
					<button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[25].contents.shortcutData[0].hyperlink.url)">
					<div class="d-flex justify-content-start align-items-center">
						<div class="document-list-icon mr-2"></div>
						<div class="">
							<p class="mb-0 documentation-list__doc-list fw700">Drawings & Reports
								<a class="tooltipss"><img src="@/assets/images/vector.png"/>
									<span class="tooltiptext">All drawings & reports from consultants such as the architect and engineers.</span>
								</a>
							</p>
						</div>
					</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="js">
import { mapState } from 'vuex'
import { connectUrl } from '@/helpers/constants'
export default {
	name: 'Home',
	data() {
		return {
			dashboard:[],
			news:'',
			todo:'',
			isLoding:true,
			isLodingNews:true,
			isLodingDoc:true,
			smartsheetUrl:connectUrl
		}
	},
	computed: {		
		...mapState(['workspace', 'projectId', 'permission'])
	},
	methods: {
		getSmartsheet() {
			fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[0].sights[0].id}`)
			.then((res) => res.json())
			.then((result) => {
				if(result.status == 'success'){
					this.news = result.data.widgets[0].contents.htmlContent
				}
			});		

			fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[0].sights[1].id}`)
			.then((res) => res.json())
			.then((result) => {
				if(result.status == 'success'){
					this.todo = result.data.widgets[0].contents.htmlContent
				}
			});
		
			if(this.workspace.folders[this.projectId].folders[0].folders[0].sights && this.workspace.folders[this.projectId].folders[0].folders[0].sights[0]){
				fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[0].folders[0].sights[0].id}`)
				.then((res) => res.json())
				.then((result) => {
					if(result.status == 'success'){
						this.dashboard = result.data
						this.isLodingDoc = false
					}
				});
			}
			this.isLoding = false
		},
		redirectToLink(link){
			window.open(link, '_blank')		
		}
	},
	created() {
		this.getSmartsheet();
	},
}
</script>
<style lang="scss">
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_icons.scss";

.clsGridGantt{width: 100%!important;}
.home-first-section {
	position: relative;
	align-items: flex-start!important;
}

.doc-section {
	@include mq("tablet", max) {
		position: absolute !important;
		top: 200%;
		left: 0px;
	}
}

.documentation-list {
	border: 1px solid #e9e8ed;
	border-radius: 16px;
	padding: 20.5px;
	margin: 18px !important;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&__doc-list {
		font-family: "DM Sans";
		font-weight: 700;
		font-size: 14px;
		color: #131834;
	}

	&__file-count {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 14px;
		color: #a2aab3;
	}

	&__upload-type {
		@extend .documentation-list__file-count;
		font-weight: 500;
	}
}

.construction-table {
	@include mq("tablet", max) {
		width: 100%;
		overflow: scroll;
	}

	.construction-update {
		width: 53% !important;
		font-weight: 300;
	}

	thead th {
		background: #f9f9fb;
		//background-color: lightgray;
	}

	.construction-table > thead th:first-child {
		border-radius: 8px 0 0 0px;
	}

	.construction-table > thead th:last-child {
		border-radius: 0 8px 0px 0;
	}
}

.construction-table > thead > tr > th {
	border: none !important;
}

.construction-table > tbody > tr > td {
	font-family: "DM Sans";
	font-weight: 300;
	font-size: 14px;
	text-align: left;
	color: #131834;
	border-top: 1px solid #e9e8ed;
}

.construction-table > tbody > tr > td {
	font-weight: 300;
}

.construction-table tbody > tr:first-child > td {
	border-top: 0 !important;
}

th {
	font-family: "DM Sans";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	text-align: left !important;
	line-height: 18px;
	color: #a2aab3;
}

.yellow-bg {
	background: #fefe8533;
}

.red-bg {
	background: #F81E1E33;
}

.to-do-list .construction-table td:nth-child(2), .to-do-list .construction-table th:nth-child(2) {
	width: 40%;
}

.construction-table tbody tr td {
	font-weight: 300 !important;
}

.sand-background {
	background: #EBE8E1 !important;
}

.margin18 {
	margin-bottom: 18px !important;
}

.ml13 {
	margin-left: 13px !important;
}

.mr13 {
	margin-right: 13px !important;
}

.ml18 {
	margin-left: 18px !important;
}

.mr18 {
	margin-right: 18px !important;
}

.fw700 {
	font-weight: 700 !important;
}


.mr0 {
	margin-right: 0px !important;
}

.ml0 {
	margin-left: 0px !important;
}

@media only screen and (min-width: 574px) {
	.documentation-list {
		margin: 18px;
	}
	.wid-mob {
		width: 180px !important;
	}
}

@media only screen and (min-width: 992px) {
	.doc{display: block!important;}
	
}
</style>
