import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import HTTPSCalls from '../src/lib/http-services'
import { library } from "@fortawesome/fontawesome-svg-core";
import Toast, { POSITION } from "vue-toastification";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fas);

// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/styles/reveloper.css'


const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon).use(store).use(router);

app.config.globalProperties.$httpServices = HTTPSCalls;

const options = {
    // You can set your default options here
    position: POSITION.BOTTOM_RIGHT
};

app.use(Toast, options);

app.mount("#app");
