<template>
	<div class="dashboard-content">
		<div class="d-flex justify-content-between align-items-center">
			<div>
				<p class="project_name margin-top-10 pdash" v-if="projectView">{{ workspace.folders[projectId].name }}</p>	
				<p class="margin-top-10" style="text-align: left;">{{permission}} access</p>			
			</div>
			<div class="dropdown profile-expand cursor mobile-hidden">
				<p
					class="dashboard-content__dashboard-profile"
					id="profileDropdownMenu"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
				>
				{{user.firstname}} {{user.lastname}}
					<span class="chevron-down-icon ml-2"></span>
				</p>

				<div
					class="dropdown-menu custom-dropdown"
					aria-labelledby="profileDropdownMenu"
				>
					<div class="d-flex align-items-center">
						<img
							src="@/assets/images/sample_profile_pic.png"
							class="ml-2 mr-2 profile_img"
						/>
						<div>
							<h5 class="user-name">{{user.firstname}} {{user.lastname}}</h5>
							<p class="profile-info">{{user.email}}</p>							
						</div>
					</div>
					<div class="divider"></div>
				
					<router-link to="/user-account" class="dropdown-item profile-info">
						<span class="horizontal-swap-icon mr-3"></span>My Account
					</router-link>

					<router-link to="" @click="logout" class="dropdown-item profile-info">
						<span class="logout-icon mr-3"></span>Logout
					</router-link>

				</div>
			</div>
		</div>
		<div class="dashboard-content__heading-border d-md-block"></div>
	</div>
</template>
<script lang="js">
import {mapState, mapActions} from 'vuex'
import * as types from '@/store/types'
export default {
	name: 'Home',
	computed: {		
		...mapState(['workspace', 'projectId', 'projectView', 'user', 'permission'])
	},	
	methods: {	
		...mapActions({logoutCustomerAPICall: types.LogoutCustomer_Action}),
		logout() {
			this.logoutCustomerAPICall();
			localStorage.removeItem('access_token');
			localStorage.removeItem('project');
			this.$router.push("/login");
		}	
	}
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_icons.scss";

.profile-expand {
	cursor: pointer;

	&.show {
		.chevron-down-icon {
			transform: rotate(180deg);
		}
	}
}

.profile_pic {
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.profile_img {
	@extend .profile_pic;
	margin-top: -20px;
}

.divider {
	border: 1px solid #E9E8ED;
	margin-bottom: 24px;
}

.user-name {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	color: #131834;
}

.profile-info {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;	
	color: #a2aab3;

	&:hover {
		color: #a2aab3;
		background: transparent;
	}
}

.dashboard-content {
	@include mq("tablet", max) {
		margin: 0 20px 0 20px;
	}

	p.project_name {
		margin-left:0px;	
		font-size: 20px;
    	font-weight: 500;	
	}

	p.project-dashboard.pdash {
		margin-left: -77px !important;
	}

	margin: 10px 31px 0 330px;

	.project_name {
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;		
		color: #131834;
		margin-bottom: 0;
	}

	.project-dashboard {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 16px;
		color: #83879b;
		margin-bottom: 0;
		margin-left: -14px;
	}

	&__dashboard-profile {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 15px;
		color: #83879b;
		margin-top: 20px;
	}

	&__heading-border {
		border-bottom: 1px solid #e9e8ed;
		margin: 14px -30px 31px;
	}
}

.custom-dropdown {
	transform: translate3d(-50px, 60px, 0px) !important;
	top: 10px !important;
	left: -96px !important;
	min-width: 260px;
	height: 200px;
	box-shadow: 4px 6px 50px rgb(51 51 51 / 5%);
	border-radius: 16px;
	padding: 10px 10px;
}

.dropdown-item {
	padding: 0px 0px 20px 0px;
}

@media (max-width: 768px) {
  .mobile-hidden {
    display: none;
  }
}
</style>



