<template>
  <div class="row project-info align-items-start">
    <div class="col-lg-7 col-12 mt-4 mt-md-0">
      <div class="dashboard-content__section-border" style="height:100%">
        <p class="dashboard-content__sub-section margin18">Project Overview</p>
        <div class="project-full-info">
          <div class="row">
            <div class="col-12 col-lg-3">
              <p class="project-overview__desc-heading proj-desc">Project Description</p>
            </div>
            <div class="col-12 col-lg-8">
              <p class="project-overview__desc-info text-left" v-if="!isLodingDirectory">
               {{  directory.info.rows[5].cells[1].displayValue }}
              </p>
            </div>
          </div>
          <div class="detail-section d-none d-lg-block"> <!--- display on desktop view-->
              <div class="project-details row">
                <div class="col-7 col-lg-3">
                  <p class="project-overview__desc-heading new-font">Total Development SF</p>
                </div>
                <div class="col-5 col-lg-3">
                  <p class="project-overview__desc-info text-lg-right mar-r" v-if="!isLoding">{{totalSF.cell.displayValue}} sq.ft.</p>
                </div>
                <div class="col-7 col-lg-3">
                  <p class="project-overview__desc-heading new-font mar-l">Street Address</p>
                </div>
                <div class="col-5 col-lg-3">
                  <p class="project-overview__desc-info text-lg-right" v-if="!isLodingDirectory"> {{ directory.info.rows[6].cells[1].displayValue}}</p>
                </div>
              </div>

              <div class="project-details row">
                <div class="col-7 col-lg-3">
                  <p class="project-overview__desc-heading new-font new-font">Project Start Date</p>
                </div>
                <div class="col-5 col-lg-3">
                  <p class="project-overview__desc-info text-lg-right mar-r" v-if="!isLodingGantt"> {{  moment(ganttChart.rows[0].cells[2].value).format('MMMM DD, YYYY') }}</p>
                </div>
                <div class="col-7 col-lg-3">
                  <p class="project-overview__desc-heading new-font mar-l">City, Province</p>
                </div>
                <div class="col-5 col-lg-3">
                  <p class="project-overview__desc-info text-lg-right" v-if="!isLodingDirectory"> {{ directory.info.rows[7].cells[1].displayValue}}</p>
                </div>
              </div>

              <div class="project-details row">
                <div class="col-7 col-lg-3">
                  <p class="project-overview__desc-heading new-font">Completion Date</p>
                </div>
                <div class="col-5 col-lg-3">
                  <p class="project-overview__desc-info text-lg-right mar-r" v-if="!isLodingGantt">{{  moment(ganttChart.rows[0].cells[3].value).format('MMMM DD, YYYY') }}</p>
                </div>
                <div class="col-7 col-lg-3">
                  <p class="project-overview__desc-heading new-font mar-l">Post Code</p>
                </div>
                <div class="col-5 col-lg-3">
                  <p class="project-overview__desc-info text-lg-right" v-if="!isLodingDirectory"> {{  directory.info.rows[8].cells[1].displayValue }}</p>
                </div>
              </div>
          </div> 
          <div class="detail-section d-block d-lg-none">  <!--- display on mobile view-->
              <div class="project-details row">
                <div class="col-6">
                  <p class="project-overview__desc-heading new-font">Total Development SF</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-info text-lg-right mar-r" v-if="!isLoding">{{totalSF.cell.displayValue}} sq.ft.</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-heading new-font new-font">Project Start Date</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-info text-lg-right mar-r" v-if="!isLodingGantt"> {{  moment(this.ganttChart.rows[0].cells[2].value).format('MMMM DD, YYYY') }}</p>
                </div>  
                
                <div class="col-6">
                  <p class="project-overview__desc-heading new-font">Completion Date</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-info text-lg-right mar-r" v-if="!isLodingGantt">{{  moment(ganttChart.rows[0].cells[3].value).format('MMMM DD, YYYY') }}</p>
                </div>
              
                <div class="col-12">
                  <p class="mobileHeading">Location</p>
                </div>

                <div class="col-6">
                  <p class="project-overview__desc-heading new-font mar-l">Street Address</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-info text-lg-right" v-if="!isLodingDirectory"> {{ directory.info.rows[6].cells[1].displayValue }}</p>
                </div>

                <div class="col-6">
                  <p class="project-overview__desc-heading new-font mar-l">City, Province</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-info text-lg-right" v-if="!isLodingDirectory"> {{ directory.info.rows[7].cells[1].displayValue}}</p>
                </div>

                <div class="col-6">
                  <p class="project-overview__desc-heading new-font mar-l">Post Code</p>
                </div>
                <div class="col-6">
                  <p class="project-overview__desc-info text-lg-right" v-if="!isLodingDirectory"> {{ directory.info.rows[8].cells[1].displayValue }}</p>
                </div>
              </div>
            </div>  
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-12 mt-4 mt-md-0">
      <img v-if="!isLodingDirectory"
      :src="directory.images[2]"
        class="rounded img-fluid img-height"
      />
    </div>
  </div>
  <div class="profile-address-details" v-if="this.permission != 'General'">
    <div class="row mt-3 align-items-start">
        <div class="col-12 col-lg-4 mt-3 mt-md-0 mb-4">
          <div class="dashboard-content__section-border">
            <p class="dashboard-content__sub-section">Owner/Client</p>
            <div class="dashboard-content__sub-section-content ml18 mr18">
              <div class="d-flex justify-content-center">
                <div class="profil-pic-parent">
                  <div class="profile-img-wrapper">
                    <img v-if="!isLodingDirectory"
                      :src="directory.images[0]"
                      loading="lazy"
                      alt=""
                      class="profil-pic"
                    />
                  </div>         
                </div>
              </div>
              <table class="profile-details"> 
                <tr>
                  <td>Contact</td>
                    <td v-if="!isLodingDirectory">{{ directory.info.rows[2].cells[2].displayValue}}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td v-if="!isLodingDirectory">{{ directory.info.rows[3].cells[2].displayValue}}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td v-if="!isLodingDirectory">{{ directory.info.rows[4].cells[2].displayValue}}</td>
                  </tr>
                  <tr>
                    <td>Street Address</td>
                    <td v-if="!isLodingDirectory">{{ directory.info.rows[6].cells[2].displayValue}}</td>
                  </tr>
                  <tr>
                    <td>City, Province</td>
                    <td v-if="!isLodingDirectory">{{ directory.info.rows[7].cells[2].displayValue}}</td>
                  </tr>
                  <tr>
                    <td>Postal Code</td>
                    <td v-if="!isLodingDirectory">{{ directory.info.rows[8].cells[2].displayValue}}</td>
                  </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-3 mt-md-0 mb-4">
          <div class="dashboard-content__section-border">
            <p class="dashboard-content__sub-section">Reveloper</p>
            <div class="dashboard-content__sub-section-content ml18 mr18">
              <div class="d-flex justify-content-center">
                <div class="profil-pic-parent">
                  <div class="profile-img-wrapper">
                    <img v-if="!isLodingDirectory"
                      :src="directory.images[1]"
                      loading="lazy"
                      alt=""
                      class="profil-pic"
                    />
                  </div>              
                </div>
              </div>
              <table class="profile-details">
                <tr>
                  <td>Manager</td>
                  <td v-if="!isLodingDirectory">{{ directory.info.rows[2].cells[3].displayValue}}</td>
                </tr>           
                <tr>
                  <td>Email</td>
                  <td v-if="!isLodingDirectory">{{ directory.info.rows[3].cells[3].displayValue}}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td v-if="!isLodingDirectory">{{ directory.info.rows[4].cells[3].displayValue}}</td>
                </tr>
                <tr>
                  <td>Street Address</td>
                  <td v-if="!isLodingDirectory">{{ directory.info.rows[6].cells[3].displayValue}}</td>
                </tr>
                <tr>
                  <td>City, Province</td>
                  <td v-if="!isLodingDirectory">{{ directory.info.rows[7].cells[3].displayValue}}</td>
                </tr>
                <tr>
                  <td>Postal Code</td>
                  <td v-if="!isLodingDirectory">{{ directory.info.rows[8].cells[3].displayValue}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>
<script lang="js">
import { connectUrl } from '@/helpers/constants'
import { mapState } from 'vuex'
import moment from 'moment';
export default {
  name: 'ProjectOverview', 
  data() {
      return {
        totalSF: 0,
        moment:moment,
        ganttChart:[],
        directory:[],
        isLoding:true,
        isLodingGantt:true,
        isLodingDirectory:true,
        smartsheetUrl:connectUrl          
      }
    },
    computed: {		
      ...mapState(['workspace', 'projectId', 'permission'])
    },
    methods: {
     getSmartsheet() {
      if(this.workspace.folders[this.projectId].folders[0].folders[0].sights && this.workspace.folders[this.projectId].folders[0].folders[0].sights[0]){
        fetch(`${this.smartsheetUrl}project-overview?id=${this.workspace.folders[this.projectId].folders[0].folders[0].sights[0].id}`)
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){ 
              this.totalSF = result.data.dashboard.widgets[37].contents.cellData.find(obj => obj.label === "Total Development SF");
              this.isLoding = false 
            }
        });
      }

        fetch(`${this.smartsheetUrl}project-directory-overview?id=${this.workspace.folders[this.projectId].folders[1].folders[0].sheets[1].id}`)
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){  
              this.directory = result.data        
              this.isLodingDirectory = false 
            }
        });

        fetch(`${this.smartsheetUrl}get-sheet?id=${this.workspace.folders[this.projectId].folders[1].folders[0].sheets[0].id}`)
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){  
              this.ganttChart = result.data 
              this.isLodingGantt = false 
            }
        });
      }
    },
    created() {
      this.getSmartsheet();
    }
}
</script>
<style  lang="scss">
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_icons.scss";
.project-info {
  @include mq("tablet", max) {
    display: flex;
    flex-direction: column-reverse;
  }
}
.profile-address-details{
  @include mq("tablet",min){
    width:100%;
    overflow: auto; 
  } 
}

.profil-pic-parent {
  position: relative;
  display: inline-block;
}
.profile-img-wrapper {
  position: relative;
  width: 9.375em;
  height: 9.375em;
  border-style: solid;
  border-width: 0.25em;
  border-color: #fff;
  border-radius: 50%;
  background-color: #fff;
  margin-bottom:0px;
}
.profil-pic-parent{
  .profile-img-wrapper {
      width: 73px !important;
      height: 78px !important;
      border-radius: 1rem !important;
      background-color: #D9D9D9 !important;}
}

.profil-pic {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
.change-photo-form {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 3.125em;
  height: 3.125em;
  cursor: pointer;
}
.profil-pic-form {
  position: relative;
}
.change-photo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 3.125em;
  height: 3.125em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 0.25em;
  border-color: #fff;
  border-radius: 50%;
  background-color: #174486;
  cursor: pointer;
}
label {
  display: block;
  margin-bottom: 0px;
  font-family: "DM Sans", sans-serif;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 500;
}
.custom-input-file {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.profile-details {
  margin-top: 24px;
  width: 100%;
  border-spacing: 0 16px;
  border-collapse: separate;
  @include mq("tablet", max) {
    /*border-spacing: 11px 16px;*/
  }
}

.profile-details tr > td:first-child {
  font-family: "DM Sans";
  font-weight: 500;
  font-size:13px;
  color: #131834;
  text-align: left;
  width: 125px;
  @include mq("tablet", max) {
    /*padding-left: 6px;*/
  font-size: 14px;

  }
}
.profile-details tr > td:last-child {
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 13px;
  color: #a2aab3;
 padding-left:0px;
  text-align: left;
  word-break: break-word;

  @include mq("tablet", max) {
    padding-left: 40px;
     font-size: 14px;
  }
}

.project-full-info {
  margin: 0 15px;
  @include mq("tablet", max) {
    margin: 0px;
  }
}

.project-overview {
  &__desc-heading {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: #131834;
    width: 171px;
    text-align: left;
    @include mq("tablet", max){
      padding: 0px 0px 0px 16px;
    }
  }
  &__desc-info {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #a2aab3;
    text-align: left;

    @include mq("tablet", max) {
      text-align: end;
      padding: 0px 16px 0px 16px;
    }
  }
}

.project-info {
  .rounded.img-fluid {
    border-radius: 15px !important;
}
}
.img-height{
  min-height:276px;
  @include mq("tablet", max){
    min-height:auto;
  }
}
.proj-desc{
  width:145px;
  @include mq("tablet", max){
    width: auto;
    margin-bottom: 8px;
  }
}
.mobileHeading{
  background: #E9E8ED;
  font-family: 'DM Sans';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 150%;
 padding: 5px 16px;
    text-align: left;
}
.leftrightmargin18{
margin-left:18px;
margin-right:18px;
@include mq("tablet", max){
  margin-left:0px;
margin-right:0px;
}
}
.new-font{
  font-weight: 500;
  font-size: 14px;
}
@media only screen and (min-width: 768px){
  .mar-r{
    margin-right: 10px;
  }
  .mar-l{
    margin-left: 10px;
  }
}
</style>