<template>
    <div>
      <canvas ref="chartCanvas"></canvas>
    </div>
  </template>
  
  <script>
  import { onMounted, ref } from 'vue';
  import Chart from 'chart.js/auto';
  
  export default {
    props: {
      jsonData: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const chartCanvas = ref(null);
  
      onMounted(() => {
        const seriesData = props.jsonData.contents.series[0].seriesData;
        const labels = seriesData.map((data) => data.x);
        const values = seriesData.map((data) => data.y);
        const colors = seriesData.map((data) => data.color);

    //       // Set different canvas size based on screen size (mobile view)
    //   if (window.innerWidth < 600) {
    //     chartCanvas.value.width = window.innerWidth * 0.8; // Adjust as needed
    //     chartCanvas.value.height = window.innerWidth * 0.8; // Adjust as needed
    //   }
  
        const ctx = chartCanvas.value.getContext('2d');
        new Chart(ctx, {
          type: 'pie',
          data: {
            labels: labels,
            datasets: [
              {
                data: values,
                backgroundColor: colors,
                borderWidth: 1, 
                circumference:180,
                rotation:270
              },
            ],
          },
          options: {
            tooltips: {
              callbacks: {
                label: function (tooltipItem, data) {
                  const label = data.labels[tooltipItem.index] || '';
                  const value = data.datasets[0].data[tooltipItem.index] || '';
         
                  const total = data.datasets[0].data.reduce((a, b) => a + b, 0);
                  const percentage = ((value / total) * 100).toFixed(2);
                  return `${label}: ${value} (${percentage}%)`;  
                },
              },
            },
            plugins: {
              legend: {
                display: true,
                position: props.jsonData.contents.legend.location,
                labels: {
                  font: {
                    family: props.jsonData.contents.legend.legendFont,
                    color: props.jsonData.contents.legend.legendFontColor,
                  },
                },
              },
            },
            elements: {
              arc: {
                borderWidth: 0,
              },
            },
          },
        });
      });
  
      return {
        chartCanvas,
      };
    },
  };
  </script>
  <style>
canvas{
    width: 100%;
    height: 100%;
}
</style>
  