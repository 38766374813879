<template>
  <div class="mt-5 pre-contrction-section preConstruction-page">
      <div class="tabs-scroll">
        <ul class="nav nav-tabs" id="costAndBudgetTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="general-tab"
              data-toggle="tab"
              href="#general"
              role="tab"
              aria-controls="general"
              aria-selected="true"
              >General</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="rent-tab"
              data-toggle="tab"
              href="#rent"
              role="tab"
              aria-controls="rent"
              aria-selected="false"
              >Timeline & Gantt Chart
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="costAndBudgetTabContent">
        <div
          class="tab-pane fade show active"
          id="general"
          role="tabpanel"
          aria-labelledby="general-tab"
        >
        <div class="row">
        <div class="col-12 my-4">
          <div class="dashboard-content__section-border">
            <p class="dashboard-content__sub-section">
              Consultant Team Directory
            </p>
            <div class="dashboard-content__sub-section-content">
              <div class="ml-2 mr-2 consultant-table-scroll">
                <div v-html="teamDirectory" class="embeded"></div>                
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
        <div
          class="tab-pane fade"
          id="rent"
          role="tabpanel"
          aria-labelledby="rent-tab"
        >
        <div class="row">
        <div class="col-12 my-4">
          <div class="dashboard-content__section-border">
            <p class="dashboard-content__sub-section">
              Pre-Construction 
            </p>
            <div class="dashboard-content__sub-section-content">
              <div class="ml-2 mr-2 consultant-table-scroll">
                <div v-html="timeline" class="embeded"></div>               
              </div>
            </div>
          </div>
        </div>
      </div>
        </div>
        
      </div>
  
      
    </div>
  
  </template>
  <script lang="js">
  import { connectUrl } from '@/helpers/constants'
  import { mapState } from 'vuex'
  export default {
    name: 'PreConstruction',
    data() {
      return {
        teamDirectory:'',
        timeline:'',
        smartsheetUrl:connectUrl
      }
    },
    computed: {		
      ...mapState(['workspace', 'projectId'])
    },
    methods: {
      getSmartsheet() {
        fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[4].sights[0].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.teamDirectory = result.data.widgets[0].contents.htmlContent
            this.isLoding = false 
          }
        });

        fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[4].sights[1].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.timeline = result.data.widgets[0].contents.htmlContent
          }
        });
      }
    },
    created() {
      this.getSmartsheet();
    }
  }
  </script>
  <style lang="scss">
  @import "@/assets/styles/_mixin.scss";
  
  //.dashboard-content__sub-section-content {
  //  @include mq("tablet", max) {
  //    .consultant-table-scroll {
  //      width: 1100px;
  //    }
  //  }
  //}
  .table-bordered td, .table-bordered th {
      border: 1px solid #E9E8ED !important;
  }
  .consultant-table tbody tr > td:first-child{
    color: #131834;
  }
  .timeline.consultant-table tbody tr > td{
    color: #131834;
  }
  .timeline.consultant-table tbody tr > td.complete{
    color: #A2AAB3;
  }
  .complete{
    color: #A2AAB3;
  }
  .consultant-table {
    thead tr > th {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 12px;
      // color: #131834;
      vertical-align: middle;
      text-align: center;
      line-height: 150%;
    }
    tbody tr > td {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 12px;
      color: #a2aab3;
      text-align: left;
      vertical-align: middle;
    }
  }
  
  .td-span {
    width:15%;
    height: 12px;
    background: #174486;
    position: absolute;
    b {
      position: absolute;
      right: -122px;
      top: -3px;
      font-weight: 500;
      font-size: 12px;
    }
  }
  tbody tr:nth-child(1) {
    .td-span {
      width: 43px;
      margin-left: 32px;
      background: #E9E8ED;
      b {
        right: -103px;
      }
    }
  }
  tbody tr:nth-child(2) {
    .td-span {
      margin-left: 33px;
      width: 94px;
      background: #E9E8ED;
      b {
        right: -122px;
      }
    }
  }
  tbody tr:nth-child(3) {
    .td-span {
      margin-left: 81px;
      width: 87px;
      b {
        right: -140px;
      }
    }
  }
  tbody tr:nth-child(4),
  tr:nth-child(6) {
    .td-span {
      margin-left: 127px;
      width: 88px;
      b {
        right: -146px;
      }
    }
  }
  tbody tr:nth-child(5) {
    .td-span {
      margin-left: 167px;
      width: 95px;
      b {
        right: -67px;
      }
    }
  }
  tbody tr:nth-child(6) {
    .td-span {
      margin-left: 127px;
      width: 88px;
      b {
        right: -150px;
      }
    }
  }
  tbody tr:nth-child(7) {
    .td-span {
      width: 77px;
      margin-left: 127px;
      b {
        right: -80px;
      }
    }
  }
  tbody tr:nth-child(8) {
    .td-span {
      width: 56px;
      margin-left: 127px;
      b {
        right: -145px;
      }
    }
  }
  tbody tr:nth-child(9) {
    .td-span {
      width: 85px;
      margin-left: 168px;
      b {
        right: -132px;
      }
    }
  }
  tbody tr:nth-child(10) {
    .td-span {
      width: 47px;
      margin-left: 215px;
      b {
        right: -84px;
      }
    }
  }
  
  .pre-contrction-section .consultant-table thead tr > th {
      color: #ffffff;
    background: #A2AAB3;
      border: 1px solid #dbdadf !important;
      
  }
  .pre-constru-img img {
  width: 103px;
  height: 42px;
  object-fit: scale-down;
  }
  
  td.location {
    background: #F2F2F2;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px !important;
      line-height: 150%;
      color: #1E1E1E;
  }
  
  .month > th {
    font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  color: #131834 !important;
  background: #E9E8ED !important;
  }
  .inprogress {
    font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  color: #52C41A !important;
  }
  .back-grey{
    background: #F9F9FB !important;
  }
  .back-grey2{
    background: #F2F2F2 !important;
  }
  .mt-5.pre-contrction-section.preConstruction-page {
    margin-top: 2rem !important;
}
  
  </style>
  