<template>
	<nav class="navbar bg-light">
		<div class="container-fluid">
			<a class="navbar-brand">
				<img src="@/assets/images/logo.png" alt="" class="nav-logo img-fluid"/>
			</a>
			<div class="dashboard-content">
				<div class="d-flex justify-content-between align-items-center userlogo">
					<img src="@/assets/images/sample_profile_pic.png" class="ml-1 mr-1 profile_img"/>					
					<div class="dropdown profile-expand cursor mobile-hidden">
						<p
							class="dashboard-content__dashboard-profile"
							id="profileDropdownMenu"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
						{{user.firstname}} {{user.lastname}}
						<span class="chevron-down-icon ml-2"></span>
						</p>

						<div class="dropdown-menu custom-dropdown" aria-labelledby="profileDropdownMenu">
							<div class="d-flex align-items-center">
								<img src="@/assets/images/sample_profile_pic.png" class="ml-2 mr-2 profile_img"/>	
								<div>
									<h5 class="user-name">{{user.firstname}} {{user.lastname}}</h5>
									<p class="profile-info">{{user.email }}</p>
								</div>
							</div>
							<div class="divider"></div>									
							<router-link to="/user-account" class="dropdown-item profile-info">
								<span class="horizontal-swap-icon mr-2"></span>My Account
							</router-link>

							<router-link to="" @click="logout" class="dropdown-item profile-info">
								<span class="logout-icon mr-2"></span>Logout
							</router-link>
						</div>
					</div>
				</div>
			</div>		
		</div>
	</nav>

	<div class="scroll-tabs-financial mt-5">
		<ul class="nav nav-tabs" id="myTab" role="tablist">
			<li class="nav-item text-white">
				<router-link
					class="nav-link"
					id="projects-tab"
					data-toggle="tab"
					role="tab"
					aria-controls="cost"
					aria-selected="true"
					:class="{ active: activeTab === 'projects' }"
					:to="{name:'projects'}"
				>My Projects</router-link>
			</li>
			<li class="nav-item">
				<router-link
					class="nav-link"
					id="finance-tab"
					data-toggle="tab"
					role="tab"
					aria-controls="finance"
					aria-selected="false"
					:class="{ active: activeTab === 'myaccount' }"
					:to="{name:'myaccount'}"
				>My Account</router-link>
			</li>
			<li class="nav-item">
				<router-link
					class="nav-link"
					id="proforma-tab"
					data-toggle="tab"
					role="tab"
					aria-controls="proforma"
					aria-selected="false"
					:class="{ active: activeTab === 'settings' }"
					:to="{name:'settings'}"
				>Settings</router-link>
			</li>
		</ul>
	</div>

	<div class="tabs-content w-tab-content">
		<router-view></router-view>
	</div>
</template>

<script lang="js">
import { mapState,mapActions} from 'vuex'
import * as types from '@/store/types'
export default {
	name: 'UserProfile',
	data() {
		return {
			activeTab: 'projects'			
		}
	},
	computed: {		
		...mapState(['user'])
	},
	methods: {	
		...mapActions({logoutCustomerAPICall: types.LogoutCustomer_Action}),
		...mapActions({getCustomerAPICall: types.GetCustomer_Action}),
		logout() {
			this.logoutCustomerAPICall();
			localStorage.removeItem('access_token');
			localStorage.removeItem('project');
			this.$router.push("/login");
		},
		getUser() {
			this.getCustomerAPICall().then(response => {
			}, err => {
				console.log("Error" + err);
			})
		}	
	},
	created() {
		this.activeTab = this.$route.name;
		if(!this.user.email){
			this.getUser();
		}	
	}	
}
</script>
<style scoped lang="scss">
@import "@/assets/styles/components.css";
@import "@/assets/styles/normalize.css";
@import "@/assets/styles/reveloper.css";
@import "@/assets/styles/_mixin.scss";

nav{
	border-bottom: 1px solid #19448326;
}
nav .container-fluid{
	flex-wrap: nowrap;
}
.nav{    flex-wrap: nowrap;}
.nav-link:hover {
    background-color: #174486;
    border-radius:unset;     
}

nav .custom-dropdown{
	width: 16rem;
}
nav .navbar-brand .nav-logo{
	width: 150px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 15px;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.profile_pic {
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.profile_img {
	@extend .profile_pic;
	// margin-top: -20px;
}

.divider {
	border: 1px solid #E9E8ED;
	margin-bottom: 10px;
}

.profile-info {
	font-family: 'DM Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;	
	color: #a2aab3;

	&:hover {
		color: #a2aab3;
		background: transparent;
	}
}

.dashboard-content {
	@include mq("tablet", max) {
		margin: 0px;
	}

	p.project_name {
		margin-left: 27px;	
		font-size: 20px;
    	font-weight: 500;	
	}

	p.project-dashboard.pdash {
		margin-left: -77px !important;
	}

	// margin: 10px 31px 0 330px;

	.project_name {
		font-family: 'DM Sans';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 150%;
		color: #131834;
		margin-bottom: 0;
	}

	.project-dashboard {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 16px;
		color: #83879b;
		margin-bottom: 0;
		margin-left: -14px;
	}

	&__dashboard-profile {
		font-family: "DM Sans";
		font-weight: 400;
		font-size: 16px;
		color: #83879b;
		margin-top: 20px;
	}

	&__heading-border {
		border-bottom: 1px solid #e9e8ed;
		margin: 14px -30px 31px;
	}
}

.custom-dropdown {
	transform: translate3d(-50px, 60px, 0px);
	top: 10px;
	left: -76px;
	min-width: 200px;
	height: 200px;
	box-shadow: 4px 6px 50px rgb(51 51 51 / 5%);
	border-radius: 16px;
	padding: 20px 10px;
}

.nav a:hover{
	text-decoration: none;
}

.nav{   
    height: 70px; 
    flex-direction: row;
}
 
.nav .logo{
	width: 15%;
	margin-left: 3%;
	line-height: 80px;
} 
.nav ul{
	text-align: end;
	// width: 80%;
	width: 72%;
}
.nav li{
   display: inline-block;
   font-size: 14px;
   list-style: none;
   text-transform: uppercase;
}

.nav-tabs .nav-link{
	height: 70px;
	line-height: 50px;
}

@media(max-width:1200px){
    .nav ul {
        margin-right: 20px;
    }
    .nav a{
        font-size: 17px;
    }

 }
 
@media(max-width:992px){

.nav ul{
    position: fixed;
    width: 50%;
    height: 100vh;
    background-color: #131834;
    // top: 80px;
    left: -100%;
    text-align: center;
	transition: all 0.9s ease;
    border-radius: 20px;
	z-index: 3;
	top: -1px;
}

.nav a{
    font-size: 16px;
}
.nav ul.show{
    left: 0;
	padding: 0;
}

.profile-info {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px; 
  color: #a2aab3;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 10px 15px;
}


}


@media only screen and (min-width: 992px)and (max-width: 1200px){
	// @media only screen and (max-width: 1200px) {
	

	.custom-dropdown {
		top: 20px; 
		min-width: 200px;
		height: 200px;
	}
	.nav{height:70px}

	.nav.nav-tabs .nav-item a{
		font-size: 14px;
		line-height: 40px;
	} 	
}


@media only screen and (min-width: 776px)and (max-width: 992px){
	nav .navbar-brand .nav-logo{
		width: 150px;
	}	

	.nav.nav-tabs .nav-item a{
		line-height: 40px;	
	} 
	.nav-tabs .nav-link{
		height: 60px
	}
	.nav{
		height: 60px
	}
}

@media only screen and (min-width: 576px)and (max-width: 776px){
	nav .navbar-brand .nav-logo{
		width: 100px;
	}	
	.nav-item{
		width: 100%;
		font-size: 10px;
	}

	.project-detail-wrapper{
		width:100%;
		text-align: center;
		padding: 20px;
	}
	.tabs-content{
		width: 100%;
	}	

	.nav .logo{
		width: 20%;
		margin-left: 2%;
		line-height: 80px;
	}
}

	// @media(max-width: 360px){
	@media only screen and (min-width: 300px)and (max-width: 576px){
		.nav-tabs .nav-link{
			line-height: 20px;
		}
		nav .navbar-brand .nav-logo{
			width: 100px;
		}	
		.custom-dropdown {
			top: 10px; 
			left:-70px;		
		}

		.d-flex .nav a{
			font-size: 12px;
		}		
	
		.nav-item{
			width: 100%;
			font-size: 10px;
		}		
		.nav.nav-tabs .nav-item a{
			font-size: 12px !important;
			height: 40px;
			z-index: 1;
		} 

		.nav-tabs{		
			height: 40px;
		}
		.project-detail-wrapper{
			width:100%;
			text-align: center;
			padding: 20px;
		}

		.tabs-content{
			width: 100%;
		}			

		.nav .logo{
			width: 30%;
			margin-left: 2%;
			line-height: 80px;
		}

		.project-img.center{
			padding: 10px;
		}
	
}
</style>

