<template>
  <div class="Construction construction-page">
      <div class="tabs-scroll">
        <ul class="nav nav-tabs" id="costAndBudgetTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="general-tab"
              data-toggle="tab"
              href="#general"
              role="tab"
              aria-controls="general"
              aria-selected="true"
              >General</a
            >
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              id="rent-tab"
              data-toggle="tab"
              href="#rent"
              role="tab"
              aria-controls="rent"
              aria-selected="false"
              >Timeline & Gantt Chart
            </a>
          </li>
        </ul>
      </div>
      <div class="tab-content" id="costAndBudgetTabContent">
        <div
          class="tab-pane fade show active"
          id="general"
          role="tabpanel"
          aria-labelledby="general-tab"
        >
          <div class="row mt-4 position-relative align-items-start">
            <div class="col-12 col-lg-8">
              <div class="dashboard-content__section-border">
                <p class="dashboard-content__sub-section">Construction Update</p>
                <div class="documentation-list mb-3">
					      <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[27].contents.shortcutData[0].hyperlink.url)">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="document-list-icon mr-2"></div>
                    <div class="">
                      <p class="mb-0 documentation-list__doc-list">
                        Latest Site Photos
                      </p>                      
                    </div>
                  </div>
						      </button>
                </div>   
                
                <!-- <div class="documentation-list mb-3">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="">
                      <p class="mb-0 documentation-list__doc-list live-lable text-left">Live Video Feed</p>                     
                      <img src="@/assets/images/livefeeds.jpg" alt="live video feed" class="livefeeds margin-top-10">
                    </div>
                  </div>
                </div> -->

                <div class="documentation-list mb-3">
					      <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[28].contents.shortcutData[0].hyperlink.url)">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="document-list-icon mr-2"></div>
                    <div class="">
                      <p class="mb-0 documentation-list__doc-list">
                        Latest Video Feed
                      </p>                      
                    </div>
                  </div>
						      </button>
                </div>
                <div class="documentation-list mb-3">
					      <button v-if="!isLodingDoc" class="bg-transparent" @click="redirectToLink(dashboard.widgets[107].contents.shortcutData[0].hyperlink.url)">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="document-list-icon mr-2"></div>
                    <div class="">
                      <p class="mb-0 documentation-list__doc-list">
                        Field Inspection
                      </p>                      
                    </div>
                  </div>
						      </button>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-4 mt-lg-0 contractor-profile mb-4">
              <div class="dashboard-content__section-border relative mb-4" v-if="this.permission == 'Contractor'">
                <p class="dashboard-content__sub-section1 back-gold documents">Contractor access</p>
                <div class="dashboard-content__sub-section-content">
                  <div class="documentation-list mb-3">
                      <button class="bg-transparent" @click="redirectToLink(contactorDashbord)">
                        <div class="d-flex justify-content-start align-items-center">
                          <div class="document-list-icon mr-2"></div>
                          <div class="">
                            <p class="mb-0 documentation-list__doc-list"> Construction Portal</p>                                  
                          </div>
                        </div>
                      </button>
                  </div>
                </div>
              </div>
              <div class="dashboard-content__section-border mb-md-0">
                <p class="dashboard-content__sub-section">General Contractor</p>
                <div class="dashboard-content__sub-section-content">
                  <div class="d-flex justify-content-center">
                    <div class="profil-pic-parent">
                      <div class="profile-img-wrapper">
                        <img v-if="!isLoding"
                        :src="jsonOutput.images[0]"
                          loading="lazy"
                          alt=""
                          class="profil-pic"
                        />
                      </div>                      
                    </div>
                  </div>
                  <table class="profile-details construction-profile-details">                    
                    <tr>
                      <td>Company</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[1].cells[10].displayValue}}</td>
                    </tr>
                    <tr>
                      <td>Project Manager</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[2].cells[10].displayValue}}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[3].cells[10].displayValue}}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[4].cells[10].displayValue}}</td>
                    </tr>
                    <tr>
                      <td>Street Address</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[6].cells[10].displayValue}}</td>
                    </tr>
                    <tr>
                      <td>City, Province</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[7].cells[10].displayValue}}</td>
                    </tr>
                    <tr>
                      <td>Postal Code</td>
                      <td v-if="!isLoding">{{jsonOutput.directory.rows[8].cells[10].displayValue}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="rent"
          role="tabpanel"
          aria-labelledby="rent-tab"
        >
          <div class="row mb-lg-3">
            <div class="col-12 my-4">
              <div class="dashboard-content__section-border">
                <p class="dashboard-content__sub-section">
                  Construction
                </p>
                <div class="dashboard-content__sub-section-content">
                  <div class="ml-2 mr-2 consultant-table-scroll">
                    <div v-html="timeline" class="embeded"></div>                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
      </div>  
    </div>
  
  </template>
  <script lang="js">
  import { connectUrl } from '@/helpers/constants' 
  import { mapState } from 'vuex' 
  export default {
    name: 'Construction',
    data() {
      return {
        contactorDashbord:'',
        jsonOutput:[],
        dashboard:[],
        timeline:'',
        isLoding:true,
        isLodingDoc:true,
        smartsheetUrl:connectUrl          
      }
    },
    computed: {		
      ...mapState(['workspace', 'projectId', 'permission'])
    },
    mounted() {
    if(this.workspace.folders[this.projectId].folders[0].folders[1].folders){
      this.contactorDashbord = this.workspace.folders[this.projectId].folders[0].folders[1].folders[0].sights[1].permalink
    }else{
      this.contactorDashbord = this.workspace.folders[this.projectId].folders[0].folders[1].sights[1].permalink
    }
  },
    methods: {
      getSmartsheet() {
        fetch(`${this.smartsheetUrl}construction-general?id=${this.workspace.folders[this.projectId].folders[1].folders[0].sheets[1].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.jsonOutput = result.data
            this.isLoding = false 
          }
        });

        fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[2].folders[5].sights[0].id}`)
        .then((res) => res.json())
        .then((result) => {
          if(result.status == 'success'){
            this.timeline = result.data.widgets[0].contents.htmlContent
          }
        }); 
        
        if(this.workspace.folders[this.projectId].folders[0].folders[0].sights && this.workspace.folders[this.projectId].folders[0].folders[0].sights[0]){
          fetch(`${this.smartsheetUrl}get-sight?id=${this.workspace.folders[this.projectId].folders[0].folders[0].sights[0].id}`)
          .then((res) => res.json())
          .then((result) => {
            if(result.status == 'success'){            
              this.dashboard = result.data
              this.isLodingDoc = false					
            }
          });	
        }
      },
      redirectToLink(link){
        window.open(link, '_blank')		
      }
    },
    created() {
      this.getSmartsheet();
    }    
  }
  </script>
  <style lang="scss">
  @import "@/assets/styles/_mixin.scss";
    
  .contractor-profile{
    @include mq("tablet",max){
   position:absolute !important;
    top:335%;
    }
   
  }
  .construction-profile-details {
    border-spacing: 6px 17px;
    @include mq("tablet", max) {
      border-spacing: 16px 16px;
    }
  }
  .Construction .profil-pic-parent .profile-img-wrapper{
    width: 100% !important
  }
  table tr.head-months th{
    background-color:#F2F2F2;
    color:#131834;
  }
  table tr.head-months th{
    text-align:center!important;
  }
   table tbody tr td.green-txt{
    color:#52C41A;
   }
   table tbody tr td.grey-txt{
    color:#A2AAB3!important;
   }
   table thead tr .mid-txt{
    text-align:center!important;
   }
  
  .Construction table tbody tr:nth-child(1) .td-span b {
      right: -64px;
  }
  .Construction table tbody tr:nth-child(1) .td-span {
      width: 40px;
  }
  .Construction table tbody tr:nth-child(2) .td-span b {
      right: -112px;
  }
  .Construction table tbody tr:nth-child(4) .td-span, .Construction table tbody tr:nth-child(6) .td-span {
      width: 94px;
  }
  .Construction table tbody tr:nth-child(6) .td-span b {
      right: -146px;
  }
  .Construction table tbody tr:nth-child(7) .td-span b {
      right: -113px;
  }
  .Construction table tbody tr:nth-child(3) .td-span {
      width: 93px;
  }
  .Construction table tbody tr:nth-child(3) .td-span b {
      right: -115px;
  }
  .Construction table tbody tr:nth-child(5) .td-span, .Construction table tbody tr:nth-child(9) .td-span {
      margin-left: 174px;
  }
  .Construction table tbody tr:nth-child(5) .td-span b {
      right: -91px;
  }
  
  .Construction table tbody tr:nth-child(8) .td-span b {
      right: -111px;
  }
  .Construction table tbody tr:nth-child(9) .td-span {
      width: 81px;
  }
  .Construction table tbody tr:nth-child(9) .td-span b {
      right: -122px;
  }
  .Construction table tbody tr:nth-child(10) .td-span, .Construction table tbody tr:nth-child(11) .td-span {
      margin-left: 220px;
      width:45px;
  }
  .Construction table tbody tr:nth-child(10) .td-span b {
      right: -121px;
  }
  .Construction table tbody tr:nth-child(11) .td-span{
      width:58px;
  }
  
  
  
  
  .Construction .contractor-profile{
    position:static!important;
  }
  //.Construction .nav-tabs .nav-item {
  //    min-width: 225px;
  //    width:auto;
  //}
//  /* Vahid Ali */
//.construction-page{margin-top:24px;}
//.construction-page .dashboard-content__sub-section{margin-bottom:20px}
//.construction-page .nav-tabs{padding-top:10px;}
//.construction-page .nav-tabs .nav-link{padding-top: 10px!important;padding-bottom: 10px!important;min-width: 230px;font-family: 'DM Sans';}
//.construction-page .nav-tabs .nav-link:hover, .construction-page .nav-tabs .nav-link:focus{background-color: #F9F9FB;}
//.construction-page .pre-constru-img img{width:auto;}
//.construction-page .construction-profile-details{border-spacing: 6px 12px;}
//.construction-page table thead tr.head-months th{padding-top:3px!important;padding-bottom:3px!important;}
//.construction-page table.consultant-table thead tr th.mid-txt{padding-top:3px!important;padding-bottom:3px!important;}
//.construction-page table tbody tr td .grey-txt2{color: #83879B !important;}
//.Construction.construction-page table tbody tr:nth-child(2) .td-span b{right: -60px;}
//.Construction.construction-page table tbody tr:nth-child(3) .td-span b {right: -185px;}
//.Construction.construction-page tbody tr:nth-child(4) .td-span b{ right: -80px;}
//.Construction.construction-page table tbody tr:nth-child(5) .td-span b { right: -125px;width:115px;}
//.Construction.construction-page tr:nth-child(6) .td-span b{right: -83px;}
//.Construction.construction-page tbody tr:nth-child(7) .td-span { width: 84px;}
//.Construction.construction-page table tbody tr:nth-child(7) .td-span b { right: -123px;}
//.Construction.construction-page tbody tr:nth-child(8) .td-span { width: 58px;}
//.Construction.construction-page table tbody tr:nth-child(8) .td-span b { right: -38px;}
//.Construction.construction-page table tbody tr:nth-child(9) .td-span b { right: -142px;width:130px;}
//.Construction.construction-page table tbody tr:nth-child(10) .td-span b { right: -135px;}
//.Construction.construction-page table tbody tr:nth-child(11) .td-span b { right: -102px;}

@media only screen and (max-width: 767px){
  .Construction.construction-page .nav.nav-tabs a{padding-left:0!important; padding-right:0!important;}
  .Construction.construction-page .nav-tabs .nav-item{font-size: 14px;}
  .Construction.construction-page .nav-tabs .nav-item.last-item{min-width:40px!important;width:40px!important;}
  .Construction.construction-page .construction-profile-details{border-spacing: 12px 12px;}
  .construction-page table thead tr th.pr-stage{ width: 205px;}
  .construction-page table thead tr th.status {width: 100px;}
  
}
  </style>
