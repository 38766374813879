<template>
	<div>
	  <div v-if="isFullScreen && isAuthenticated">
		<SideMenu/>
		<Header/>
		<div class="dashboard-content">	
		  <router-view />
		</div>
	  </div>
	  <div v-if="!isFullScreen">
		<div class="full-screen-content">
		  <router-view />
		</div>
	  </div>
	</div>
</template> 

<script lang="js">
import {mapActions, mapGetters, mapState} from 'vuex'
import * as types from '@/store/types'
import Header from '@/components/header/Header.vue'
import SideMenu from '@/components/sidemenu/SideMenu.vue'
import { connectUrl } from '@/helpers/constants'
export default {
	components: {
		Header,
		SideMenu
	},	
	data() {
		return {				
			smartsheetUrl:connectUrl
		}
	},
	computed: {	
		...mapGetters({isAuthenticated: types.SetAuth_Getter}),
		isFullScreen() {
			return ![
				'login',
				'Projects',
				'createAccount',
				'userprofile',
				'projects',
				'myaccount',
				'settings',			
				'ForgetPassword',
				'ResetPassword'
			].includes(this.$route.name)
		}		
	},
	created() {
		if (this.isFullScreen && this.isAuthenticated) {			
			this.getWorkspace()
			this.getUser()
		}		
	},		
	methods: {
		...mapActions({getWorkspaceAPI: types.GetWorkspace_Action}),
		...mapActions({getCustomerAPICall: types.GetCustomer_Action}),
		getWorkspace() {
			this.getWorkspaceAPI().then(response => {				
			}, err => {
				console.log("Error" + err);
			})
		},
		getUser() {
			this.getCustomerAPICall().then(response => {
			}, err => {
				console.log("Error" + err);
			})
		}		
	}		
}
</script>
<style lang="scss">
@import "@/assets/styles/_mixin.scss";

.dashboard-content {
	@include mq("tablet", max) {
		margin: 0 20px 0 20px;
	}
	margin: 10px 31px 0 330px;
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
	.dashboard-content {
		margin: 10px 10px 0 295px !important;
	}
	.dashboard-content[data-v-ed558de2] {
		margin: 10px 10px 0 295px !important;
	}
}

.full-screen-content {
	@include mq("tablet", max) {
		margin: 0 0 0 0;
	}
	margin: 0 0 0 0;
}

</style>
