/* eslint-disable */
import axios from 'axios'
import router from '../router'

let httpServices;

class HttpServices{
    constructor(){
        httpServices = axios.create({
            //withCredentials: true,
            timeout:90000
        });
        
    httpServices.defaults.headers["Content-Type"]='application/json';
    }

    all=axios.all;
    spread = axios.spread;

    authHeader = (configurations) =>{
        
        var requestConfig ={
            ...configurations
        };
        requestConfig.headers={};

        if(configurations.isCORS){
            requestConfig.headers["Content-Type"]="applications/json";
        }
        requestConfig.headers["authorization"]=`${localStorage.getItem('access_token')}`;

        return requestConfig;
    };

    get = (url, configurations) => {
        return httpServices.get(url, this.authHeader(configurations || {}));
    }

    post = (url, data, configurations) =>{
        return httpServices.post(url, data, this.authHeader(configurations || {}) );
    }   
}

const HTTPSCalls = new HttpServices();

export default HTTPSCalls;

